export const PRIMARY_BUTTON = 'primary';
export const SECONDARY_BUTTON = 'secondary';
export const WARNING_BUTTON = 'warning';
export const WARNING_SECONDARY_BUTTON = 'warning-secondary';
export const RM_BUTTON = 'monitoring';

export const DOCTOR_SIGN_TYPE = {
  0: 'Нет',
  1: 'Мать и Дитя',
  2: 'FoodFessional',
  3: 'Evogen',
  4: 'РТ Здоровье',
};

export const SEX_TYPE = {
  1: 'Мужской',
  2: 'Женский',
};

export const GENDERS = [
  {
    value: true,
    title: 'Мужской',
  },
  {
    value: false,
    title: 'Женский',
  },
];

export const PAYMENT_VARIANTS = [
  {
    value: true,
    title: 'С оплатой',
  },
  {
    value: false,
    title: 'Без оплаты',
  },
];

export const COMMUNICATION_METHODS = [
  {
    id: 0,
    title: 'Текст',
    value: 'Text',
  },
  {
    id: 1,
    title: 'Аудио',
    value: 'Audio',
  },
  {
    id: 2,
    title: 'Видео',
    value: 'Video',
  },
];

export const SEARCH_FIELDS = [
  {
    alias: 'phone',
    title: 'Номеру телефона',
  },
  {
    alias: 'fio',
    title: 'ФИО',
  },
  {
    alias: 'policy',
    title: 'По номеру полиса',
  },
];

export const POLICY_TYPES = [
  {
    id: 2,
    title: 'Все',
  },
  {
    id: 0,
    title: 'ДМС',
  },
  {
    id: 1,
    title: 'ОМС',
  },
];

export const CONTACT_TYPE = [
  {
    title: 'Контакты для записи',
    type: 'appointmentContacts',
    index: 1,
  },
  {
    title: 'Контакты для рассылки',
    type: 'legalContacts',
    index: 2,
  },
  {
    title: 'Гарантийные контакты',
    type: 'indemnityContact',
    index: 3,
  },
  {
    title: 'Контакты для клиентского сервиса',
    type: 'clientServiceContact',
    index: 4,
  },
];

export class POLICY_TYPE {
  static types = {
    0: 'DMS',
    1: 'OMS',
    2: 'All',
  }

  static decodeType(type) {
    return this.types[type];
  }

  static codeType(type) {
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(this.types)) {
      if (type === value) {
        return parseInt(key, 10);
      }
    }
  }
}

export const dutyDoctorsIds = [1, 2, 3, 4, 5];

export const DOCTOR_ACCESS_TYPE = {
  0: 'Виден всем',
  1: 'Пациентам из клиники врача',
  2: 'Врачам клиники при повторной записи',
  3: 'Только в определенных подпрограммах',
};

export const CLINIC_SERVICES = {
  0: 'ПНД',
  1: 'Полный стационар',
  2: 'Дневной стационар',
  3: 'Роды',
  4: 'Телемед',
  5: 'ЭКО',
  6: 'Ведение беременности',
  7: 'Онкология',
  8: 'АПП',
};

export const CLINIC_PRIORITIES = [
  {
    title: 'Не выбран',
    index: 0,
  },
  {
    title: 'Приоритетная',
    index: 1,
  },
  {
    title: 'Не определен',
    index: 2,
  },
  {
    title: 'Не отправляем',
    index: 3,
  },
];

export const CONSULTATION_STATUSES = [
  {
    title: 'Открыта',
    index: 0,
  },
  {
    title: 'В работе',
    index: 1,
  },
  {
    title: 'Закрыта',
    index: 2,
  },
  {
    title: 'Консилиум',
    index: 3,
  },
  {
    title: 'Ожидает оплаты',
    index: 4,
  },
  {
    title: 'Отменена',
    index: 6,
  },
  {
    title: 'Таймаут оплаты',
    index: 7,
  },
  {
    title: 'Отменена пациентом',
    index: 8,
  },
  {
    title: 'Отменена врачом',
    index: 9,
  },
  {
    title: 'Забронировано',
    index: 10,
  },
  {
    title: 'Отмена бронирования',
    index: 11,
  },
];

export const CONSULTATION_STATUSES_FOR_CLINIC_ADMIN = [
  {
    title: 'Открыта',
    index: 0,
  },
  {
    title: 'В работе',
    index: 1,
  },
  {
    title: 'Закрыта',
    index: 2,
  },
  {
    title: 'Отменена',
    index: 6,
  },
  {
    title: 'Отменена пациентом',
    index: 8,
  },
  {
    title: 'Отменена врачом',
    index: 9,
  },
];

export const CONSULTATION_TYPES = [
  {
    title: 'Пациент-Врач',
    index: 0,
  },
  {
    title: 'Врач-Врач',
    index: 1,
  },
];
export const CREATE_PATIENT_MODE = {
  ForIndemnity: 0,
  ForDoctis: 1,
};

export const FORMATS_FOR_PREVIEW = ['JPEG', 'JPG', 'PNG', 'HEIC', 'MP4', 'HEVC'];

export const ALLOWED_FORMATS = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/heic',
  'application/pdf',
  'video/mp4',
  'video/hevc',
];

export const MONTHS = [
  {
    id: 1,
    ru: 'Январь',
    en: 'January',
  },
  {
    id: 2,
    ru: 'Февраль',
    en: 'February',
  },
  {
    id: 3,
    ru: 'Март',
    en: 'March',
  },
  {
    id: 4,
    ru: 'Апрель',
    en: 'April',
  },
  {
    id: 5,
    ru: 'Май',
    en: 'May',
  },
  {
    id: 6,
    ru: 'Июнь',
    en: 'June',
  },
  {
    id: 7,
    ru: 'Июль',
    en: 'July',
  },
  {
    id: 8,
    ru: 'Август',
    en: 'August',
  },
  {
    id: 9,
    ru: 'Сентябрь',
    en: 'September',
  },
  {
    id: 10,
    ru: 'Октябрь',
    en: 'October',
  },
  {
    id: 11,
    ru: 'Ноябрь',
    en: 'November',
  },
  {
    id: 12,
    ru: 'Декабрь',
    en: 'December',
  },
];

export const DOMAIN_SETTINGS = {
  default: {
    logo: 'doctis-logo-colored',
    title: 'CRM Doctis',
    favicon: 'favicon',
    isGostelemed: false,
  },
  gostelemed: {
    logo: 'gostelemed-logo',
    title: 'Гостелемед',
    favicon: 'gostelemed-favicon',
    phone: '8 800 222 6164',
    isGostelemed: true,
  },
  fmba: {
    logo: 'fmba-logo',
    title: 'ФМБА',
    favicon: 'fmba-favicon',
    isGostelemed: true,
  },
  nn: {
    logo: 'nn-logo',
    title: '',
    favicon: 'nn-favicon',
    isGostelemed: true,
  },
  kurgan: {
    logo: 'kurgan-logo',
    title: '',
    favicon: 'kurgan-favicon',
    isGostelemed: true,
  },
  mo: {
    logo: 'mo-logo',
    title: 'Наша поликлиника',
    favicon: 'mo-favicon',
    phone: '8 800 222 6164',
    isGostelemed: true,
  },
  mariel: {
    logo: 'mariel-logo',
    title: 'Марий Эл',
    favicon: 'mariel-favicon',
    phone: '8 800 222 6164',
    isGostelemed: true,
  },
  buryatia: {
    logo: 'buryatia-logo',
    title: 'Бурятия',
    favicon: 'buryatia-favicon',
    phone: '8 800 222 6164',
    isGostelemed: true,
  },
  dagestan: {
    logo: 'dagestan-logo',
    title: 'Дагестан',
    favicon: 'dagestan-favicon',
    phone: '8 800 222 6164',
    isGostelemed: true,
  },
  astrakhan: {
    logo: 'astrakhan-logo',
    title: 'Астрахань',
    favicon: 'astrakhan-favicon',
    phone: '8 800 222 6164',
    isGostelemed: true,
  },
};

export const CERTIFICATE_TYPE = {
  0: 'None',
  2: 'DoctisPatient',
  3: 'DoctisPatientVoip',
  4: 'DoctisDoctor',
  10: 'MyHelmet',
  12: 'MyHelmetVoip',
  14: 'FoodFessional',
  16: 'FoodFessionalVoip',
  18: 'MyHealth',
  20: 'MyHealthVoip',
  22: 'Gostelemed',
  24: 'GostelemedVoip',
};

export const CLIENT_TYPE = {
  0: 'Unknown',
  1: 'DoctorApp',
  2: 'PatientApp',
  3: 'AdminApp',
  4: 'Crm',
};

export const CLIENT_PLATFORM = {
  0: 'Unknown',
  1: 'DoctisAndroid',
  2: 'DoctisIos',
  4: 'MyHelmetIos',
  5: 'MyHelmetAndroid',
  6: 'Web',
  7: 'FoodFessionalIos',
  8: 'FoodFessionalAndroid',
  9: 'MyHealthIos',
  10: 'MyHealthAndroid',
  11: 'GostelemedIos',
  12: 'GostelemedAndroid',
  13: 'Telemed52Ios',
  14: 'Telemed52Android',
  15: 'RtHealthIos',
  16: 'RtHealthAndroid',
  17: 'TelemedDagestanIos',
  18: 'TelemedDagestanAndroid',
  19: 'TelemedBuryatiaIos',
  20: 'TelemedBuryatiaAndroid',
  21: 'MoscowRegionIos',
  22: 'MoscowRegionAndroid',
  23: 'TelemedFmbaIos',
  24: 'TelemedFmbaAndroid',
};

export const ROUTE_COMPONENTS = {
  '/doctors': {
    path: '/doctors',
    component: () => import('@/pages/Doctors'),
  },
  '/doctors-evaluation': {
    path: '/doctors-evaluation',
    component: () => import('@/pages/DoctorEvaluation'),
  },
  '/doctor-schedule': {
    path: '/doctor-schedule',
    component: () => import('@/pages/DoctorSchedule'),
  },
  '/clinics': {
    path: '/clinics',
    component: () => import('@/pages/Clinics'),
  },
  '/clinics/:clinicId': {
    path: '/clinics/:clinicId',
    component: () => import('@/components/Clinics/ClinicPage/ClinicPage'),
    props(route) {
      return ({ clinicId: route.params.clinicId });
    },
  },
  '/clinics-regions': {
    path: '/clinics-regions',
    component: () => import('@/components/Clinics/Regions/RegionsPage'),
  },
  '/clinics-history': {
    path: '/clinics-history',
    component: () => import('@/components/Clinics/ClinicsHistory'),
  },
  '/insurance-programs/program/subprogram-create-oms': {
    path: '/insurance-programs/program/subprogram-create-oms',
    component: () => import('@/components/InsurancePrograms/InsuranceSubprogramCreatePageOMS'),
  },
  '/insurance-programs/program/subprogram-create-dms': {
    path: '/insurance-programs/program/subprogram-create-dms',
    component: () => import('@/components/InsurancePrograms/InsuranceSubprogramCreatePageDMS'),
  },
  '/insurance-companies': {
    path: '/insurance-companies',
    component: () => import('@/pages/InsuranceCompanies'),
  },
  '/insurance-import': {
    path: '/insurance-import',
    component: () => import('@/components/InsurancePrograms/InsuranceImport'),
  },
  '/insurance-companies/new': {
    path: '/insurance-companies/new',
    component: () => import('@/components/InsurancePrograms/InsuranceCompanyEdit'),
  },
  '/insurance-companies/:company/edit': {
    path: '/insurance-companies/:company/edit',
    component: () => import('@/components/InsurancePrograms/InsuranceCompanyEdit'),
  },
  '/insurance-companies/:company/programs': {
    path: '/insurance-companies/:company/programs',
    component: () => import('@/components/InsurancePrograms/InsuranceProgramPage'),
  },
  '/insurance-companies/:company/programs/:program/subprograms/:subprogram': {
    path: '/insurance-companies/:company/programs/:program/subprograms/:subprogram',
    component: () => import('@/components/InsurancePrograms/InsuranceSubprogramDetail'),
  },
  '/insurance-companies/:company/programs/:program/subprograms/:subprogram/edit': {
    path: '/insurance-companies/:company/programs/:program/subprograms/:subprogram/edit',
    component: () => import('@/components/InsurancePrograms/InsuranceSubprogramEdit'),
  },
  '/insurance-companies/:company/subprograms/new': {
    path: '/insurance-companies/:company/subprograms/new',
    component: () => import('@/components/InsurancePrograms/InsuranceSubprogramEdit'),
  },
  '/consultation-feedback': {
    path: '/consultation-feedback',
    component: () => import('@/pages/ConsultationFeedback'),
  },
  '/consultation-feedback-info/:id': {
    path: '/consultation-feedback-info/:id',
    component: () => import('@/pages/ConsultationFeedbackInfo'),
    props(route) {
      return ({ reviewId: route.params.id });
    },
  },
  '/patients': {
    path: '/patients',
    component: () => import('@/pages/Patients'),
  },
  '/family-policies': {
    path: '/family-policies',
    component: () => import('@/pages/FamilyPolicies'),
  },
  '/appeals': {
    path: '/appeals',
    component: () => import('@/pages/Appeals'),
  },
  '/patients/import': {
    path: '/patients/import',
    component: () => import('@/components/InsurancePrograms/InsuranceImport'),
  },
  '/patients/history-actions': {
    path: '/patients/history-actions',
    component: () => import('@/pages/Patients/PatientsHistoryOfActions'),
  },

  '/patients/calling-monitoring': {
    path: '/patients/calling-monitoring',
    component: () => import('@/pages/Patients/CallingMonitoring'),
  },

  '/statistics': {
    path: '/statistics',
    component: () => import('@/pages/Statistics'),
  },
  '/insurance-companies/import': {
    path: '/insurance-companies/import',
    component: () => import('@/components/InsurancePrograms/InsuranceProgramReestrImport'),
  },
  '/reports': {
    path: '/reports',
    component: () => import('@/pages/Reports'),
  },
  '/laboratory-partners': {
    path: '/laboratory-partners',
    component: () => import('@/pages/LaboratoryPartners'),
  },
  '/laboratory-promocodes': {
    path: '/laboratory-promocodes',
    component: () => import('@/pages/LaboratoryPromocodes'),
  },
  '/laboratory-tags': {
    path: '/laboratory-tags',
    component: () => import('@/pages/LaboratoryTags'),
  },
  '/laboratory-legal-partners': {
    path: '/laboratory-legal-partners',
    component: () => import('@/pages/LaboratoryLegalPartners'),
  },
  '/laboratory-partner-service': {
    path: '/laboratory-partner-service',
    component: () => import('@/pages/LaboratoryPartnerService'),
  },
  '/import-partner-prices': {
    path: '/import-partner-prices',
    component: () => import('@/components/Laboratories/ImportPartnerPrices'),
  },
  '/laboratory-bind-service': {
    path: '/laboratory-bind-service',
    component: () => import('@/pages/LaboratoryBindServiceRedirectPage'),
  },
  '/laboratory-bind-service/:id': {
    path: '/laboratory-bind-service/:id',
    component: () => import('@/pages/LaboratoryBindServicePage'),
    props(route) {
      return ({ partnerServiceId: route.params.id });
    },
  },
  '/laboratory-gluing-service': {
    path: '/laboratory-gluing-service',
    component: () => import('@/pages/LaboratoryGluingService'),
  },
  '/laboratory-branches': {
    path: '/laboratory-branches',
    component: () => import('@/pages/LaboratoryBranches'),
  },
  '/laboratory-import-branches': {
    path: '/laboratory-import-branches',
    component: () => import('@/components/Laboratories/LaboratoryImportBranches'),
  },
  '/laboratory-cities': {
    path: '/laboratory-cities',
    component: () => import('@/pages/LaboratoryCities'),
  },
  '/laboratory-service': {
    path: '/laboratory-service',
    component: () => import('@/pages/LaboratoryService'),
  },
  '/preparing-analysis': {
    path: '/preparing-analysis',
    component: () => import('@/pages/PreparingAnalysis'),
  },
  '/laboratory-orders': {
    path: '/laboratory-orders',
    component: () => import('@/pages/LaboratoryOrders'),
  },
  '/laboratory-order-info/:id': {
    path: '/laboratory-order-info/:id',
    component: () => import('@/pages/LaboratoryOrderInfo'),
    props(route) {
      return ({ orderId: route.params.id });
    },
  },
  '/prices': {
    path: '/prices',
    component: () => import('@/pages/Prices'),
  },
  '/gluing-services': {
    path: '/gluing-services',
    component: () => import('@/pages/GluingServices'),
  },
  '/non-glued-services': {
    path: '/non-glued-services',
    component: () => import('@/pages/NonGluedServices'),
  },
  '/consultations': {
    path: '/consultations',
    component: () => import('@/pages/Consultations'),
    props(route) {
      const status = parseInt(route.query.status, 10);
      // eslint-disable-next-line no-restricted-globals
      return ({ status: isNaN(status) ? null : status });
    },
  },
  '/consultations-control': {
    path: '/consultations-control',
    component: () => import('@/pages/ConsultationsControl'),
  },
  '/consultations-reservation': {
    path: '/consultations-reservation',
    component: () => import('@/pages/ConsultationsReservation'),
  },
  '/sms': {
    path: '/sms',
    component: () => import('@/pages/SmsNew'),
  },
  '/diseases': {
    path: '/diseases',
    component: () => import('@/pages/Diseases'),
  },
  '/promocodes': {
    path: '/promocodes',
    component: () => import('@/pages/PromocodesNew'),
  },
  '/schedule': {
    path: '/schedule',
    component: () => import('@/pages/ScheduleNew'),
  },
  '/specializations': {
    path: '/specializations',
    component: () => import('@/pages/Specializations'),
  },
  '/users': {
    path: '/users',
    component: () => import('@/pages/Users'),
  },
  '/policy-list': {
    path: '/policy-list',
    component: () => import('@/pages/PolicyList'),
  },
  '/policy-moderation': {
    path: '/policy-moderation',
    component: () => import('@/pages/PolicyModeration'),
  },
  '/policy-moderation/:id': {
    path: '/policy-moderation/:id',
    component: () => import('@/pages/PolicyModerationInfo'),
  },
  '/documents': {
    path: '/documents',
    component: () => import('@/pages/Documents'),
  },
  '/legal-entities': {
    path: '/legal-entities',
    component: () => import('@/pages/LegalEntities'),
  },
  '/legal-entities/:legalPersonId': {
    path: '/legal-entities/:legalPersonId',
    component: () => import('@/pages/LegalEntity'),
    props(route) {
      return ({ legalPersonId: route.params.legalPersonId });
    },
  },
  '/legal-entities/import/:legalPersonId': {
    path: '/legal-entities/import/:legalPersonId',
    component: () => import('@/components/LegalEntities/LegalEntityReestrImport'),
    props(route) {
      return ({ legalPersonId: route.params.legalPersonId });
    },
  },
  '/registry-check-history': {
    path: '/registry-check-history',
    component: () => import('@/pages/RegistryCheckHistory'),
  },
  '/roles': {
    path: '/roles',
    component: () => import('@/pages/Roles'),
  },
  '/pages': {
    path: '/pages',
    component: () => import('@/pages/Pages'),
  },
  '/remote-monitoring/tasks': {
    path: '/remote-monitoring/tasks',
    component: () => import('@/pages/RemoteMonitoringTasks'),
  },
  '/remote-monitoring/stats': {
    path: '/remote-monitoring/stats',
    component: () => import('@/pages/RemoteMonitoringStats'),
  },
  '/remote-monitoring/import': {
    path: '/remote-monitoring/import',
    component: () => import('@/pages/RemoteMonitoringImport'),
  },

  // Стр. таблицы мониторингов для модуля Дист. Мониторинга
  '/remote-monitoring/monitorings-table/': {
    path: '/remote-monitoring/monitorings-table/',
    component: () => import('@/pages/RemoteMonitoringMonitoringsTable'),
  },

  // Стр.Списка Анкет Дист.Мониторинга
  '/remote-monitoring/questionnaires': {
    path: '/remote-monitoring/questionnaires',
    component: () => import('@/pages/RemoteMonitoringAdmin/RemoteMonitoringQuestionnaires'),
  },

  // Стр. Анкеты Дист.Мониторинга
  '/remote-monitoring/questionnaires/view/:id': {
    path: '/remote-monitoring/questionnaires/view/:id',
    component: () => import('@/pages/RemoteMonitoringAdmin/RemoteMonitoringQuestionnairesView'),
    props(route) {
      return ({ id: route.params.id });
    },
  },

  // Стр. Создания Анкеты Дист.Мониторинга
  '/remote-monitoring/questionnaires/add/': {
    path: '/remote-monitoring/questionnaires/add/',
    component: () => import('@/pages/RemoteMonitoringAdmin/RemoteMonitoringQuestionnairesAdd'),
  },

  // Стр. Списка индикаторов Дист.Мониторинга
  '/remote-monitoring/indicators/': {
    path: '/remote-monitoring/indicators/',
    component: () => import('@/pages/RemoteMonitoringAdmin/RemoteMonitoringIndicators'),
  },

  // Стр. Создания индикатора Дист.Мониторинга
  '/remote-monitoring/indicators/add/': {
    path: '/remote-monitoring/indicators/add/',
    component: () => import('@/pages/RemoteMonitoringAdmin/RemoteMonitoringIndicatorsAdd'),
  },

  // Стр. Единиц измерения индикатора Дист.Мониторинга
  '/remote-monitoring/indicators/units/': {
    path: '/remote-monitoring/indicators/units/',
    component: () => import('@/pages/RemoteMonitoringAdmin/RemoteMonitoringIndicatorUnits'),
  },

  // Стр. Индикатора Дист.Мониторинга
  '/remote-monitoring/indicators/view/:id': {
    path: '/remote-monitoring/indicators/view/:id',
    component: () => import('@/pages/RemoteMonitoringAdmin/RemoteMonitoringIndicatorsView'),
    props(route) {
      return ({ id: route.params.id });
    },

  },

  // Стр. Шаблонов правил Дист.Мониторинга
  '/remote-monitoring/scoring-rules/': {
    path: '/remote-monitoring/scoring-rules/',
    component: () => import('@/pages/RemoteMonitoringAdmin/RemoteMonitoringScoringRules'),
  },

  // Стр. Шаблонов сообщени Дист. Мониторинга
  '/remote-monitoring/messages-templates/': {
    path: '/remote-monitoring/messages-templates/',
    component: () => import('@/pages/RemoteMonitoringAdmin/RemoteMonitoringMessagesTemplates'),
  },

  // Стр. Шаблонов Дист. Мониторинга
  '/remote-monitoring/templates/': {
    path: '/remote-monitoring/templates/',
    component: () => import('@/pages/RemoteMonitoringAdmin/RemoteMonitoringTemplates'),
  },

  // Стр. Шаблонов Дист. Мониторинга
  '/remote-monitoring/templates/add/': {
    path: '/remote-monitoring/templates/add/',
    component: () => import('@/pages/RemoteMonitoringAdmin/RemoteMonitoringTemplatesAdd'),
  },

  // Стр. Шаблонов Дист. Мониторинга
  '/remote-monitoring/templates/view/:id/': {
    path: '/remote-monitoring/templates/view/:id/',
    component: () => import('@/pages/RemoteMonitoringAdmin/RemoteMonitoringTemplatesView'),
    props(route) {
      return ({ id: route.params.id });
    },
  },

  // Стр. таскок Дист. Мониторинга
  '/remote-monitoring/tasks-list/': {
    path: '/remote-monitoring/tasks-list/',
    component: () => import('@/pages/RemoteMonitoringAdmin/RemoteMonitoringTasks'),
  },

  // Стр. таскок Дист. Мониторинга
  '/remote-monitoring/indicator-trends/': {
    path: '/remote-monitoring/indicator-trends/',
    component: () => import('@/pages/RemoteMonitoringAdmin/RemoteMonitoringIndicatorTrends'),
  },

  // Стр. Шаблонов Дист. Мониторинга
  '/remote-monitoring/indicator-trends/view/:id/': {
    path: '/remote-monitoring/indicator-trends/view/:id/',
    component: () => import('@/pages/RemoteMonitoringAdmin/RemoteMonitoringIndicatorTrendsView'),
    props(route) {
      return ({ id: route.params.id });
    },
  },

  // Стр. Шаблонов Дист. Мониторинга
  '/remote-monitoring/indicator-trends/create/': {
    path: '/remote-monitoring/indicator-trends/create/',
    component: () => import('@/pages/RemoteMonitoringAdmin/RemoteMonitoringIndicatorTrendsCreate'),
  },

  '/patient-appointment': {
    path: '/patient-appointment',
    component: () => import('@/pages/PatientAppointment'),
  },
  '/referral-duty-consultation-import': {
    path: '/referral-duty-consultation-import',
    component: () => import('@/components/Consultations/ReferralDutyConsultationImport'),
  },
  '/queues': {
    path: '/queues',
    component: () => import('@/pages/Queues'),
  },
  '/queues/tags': {
    path: '/queues/tags',
    component: () => import('@/components/Queues/QueuesTags'),
  },
  '/queues/control': {
    path: '/queues/control',
    component: () => import('@/components/Queues/QueuesControl'),
  },
  '/insurance-import-list': {
    path: '/insurance-import-list',
    component: () => import('@/pages/InsuranceImportList'),
  },
  '/settings': {
    path: '/settings',
    component: () => import('@/pages/Settings'),
  },
  '/banner-management': {
    path: '/banner-management',
    component: () => import('@/pages/BannerManagement'),
  },
  '/stories': {
    path: '/stories',
    component: () => import('@/pages/Stories/StoriesList'),
  },
  '/stories/story-create/': {
    path: '/stories/story-create/',
    component: () => import('@/pages/Stories/StoryCreate'),
  },
  '/stories/story-info/:id': {
    path: '/stories/story-info/:id',
    component: () => import('@/pages/Stories/StoryInfo'),
    props(route) {
      return ({ storyId: route.params.id });
    },
  },

  // Учет мед. приборов
  '/medical-devices': {
    path: '/medical-devices',
    component: () => import('@/pages/MedicalDevices/MedicalDevices'),
  },

  // Учет регистрационных удостоверений
  '/medical-devices/registration-sertificates': {
    path: '/medical-devices/registration-sertificates',
    component: () => import('@/pages/MedicalDevices/RegistrationSertificates'),
  },

  // Модели мед. приборов
  '/medical-devices/models': {
    path: '/medical-devices/models',
    component: () => import('@/pages/MedicalDevices/MedicalDevicesModels'),
  },

  // Выдача \ прием мед.приборов
  '/remote-monitoring/medical-devices-control': {
    path: '/remote-monitoring/medical-devices-control',
    component: () => import('@/pages/MedicalDevices/MedicalDevicesControl'),
  },

  // Анализатор КТГ
  '/ctg-analizator-result': {
    path: '/ctg-analizator-result',
    component: () => import('@/pages/CtgAnalizatorPage'),
  },
  '/403': {
    path: '/403',
    component: () => import('@/pages/ErrorPage403'),
  },

  // Школа мам
  '/mom-school': {
    path: '/mom-school',
    component: () => import('@/pages/MomSchool'),
  },
};

export const shortcutDays = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Cб'];

export const shortcutDaysForCalendars = [
  'Пн',
  'Вт',
  'Ср',
  'Чт',
  'Пт',
  'Cб',
  'Вс',
];

export const shortcutMonths = [
  'Янв',
  'Фев',
  'Мар',
  'Апр',
  'Мая',
  'Июн',
  'Июл',
  'Авг',
  'Сен',
  'Окт',
  'Ноя',
  'Дек',
];

export const calendarMonths = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

export const SIGNS_AGENCY_AGREEMENT = [
  {
    value: 0,
    text: 'Агентский договор',
  },
  {
    value: 1,
    text: 'Телемед',
  },
  {
    value: 2,
    text: 'Очная помощь',
  },
];

export const PRICE_TYPES = [
  {
    id: 0,
    title: 'ДМС',
  },
  {
    id: 1,
    title: 'Индивидуальный',
  },
  {
    id: 2,
    title: 'Коробочная версия',
  },
  {
    id: 3,
    title: 'Телемедицина',
  },
];

export const BANNER_STYLE = {
  WHITE: 0,
  PURPLE: 1,
  ORANGE: 2,
  BLUE: 3,
  GREEN: 4,
  PINK: 5,
  AQUAMARINE: 6,
};

export const BANNER_STYLE_INFO = [
  {
    id: 0,
    key: 'White',
    bgColor: '#fff',
    textColor: '#000',
    title: 'Белый',
  },
  {
    id: 1,
    key: 'Purple',
    bgColor: 'purple',
    textColor: '#fff',
    title: 'Фиолетовый',
  },
  {
    id: 2,
    key: 'Orange',
    bgColor: 'orange',
    textColor: '#fff',
    title: 'Оранжевый',
  },
  {
    id: 3,
    key: 'Blue',
    bgColor: 'blue',
    textColor: '#fff',
    title: 'Синий',
  },
  {
    id: 4,
    key: 'Green',
    bgColor: 'green',
    textColor: '#000',
    title: 'Зеленый',
  },
  {
    id: 5,
    key: 'Pink',
    bgColor: 'pink',
    textColor: '#fff',
    title: 'Розовый',
  },
  {
    id: 6,
    key: 'Aquamarine',
    bgColor: 'aquamarine',
    textColor: '#000',
    title: 'Аквамариновый',
  },
];

export const OFFERS = [
  {
    id: 0,
    title: '(Для взрослого) Оферта',
    requestKey: 'offer',
  },
  {
    id: 1,
    title: '(Для ребенка) Оферта',
    requestKey: 'childOffer',
  },
  {
    id: 2,
    title: '(Для взрослого) Согласие на предоставление персональных данных в клинику',
    requestKey: 'personalDataAgreement',
  },
  {
    id: 3,
    title: '(Для ребенка) Согласие на предоставление персональных данных в клинику',
    requestKey: 'childPersonalDataAgreement',
  },
  {
    id: 4,
    title: '(Для взрослого) Информированное добровольное согласие на медицинское вмешательство',
    requestKey: 'medicalInterventionAgreement',
  },
  {
    id: 5,
    title: '(Для ребенка) Информированное добровольное согласие на медицинское вмешательство',
    requestKey: 'childMedicalInterventionAgreement',
  },
];

export const HASHTAGS = [
  {
    title: 'ФИО Клиента',
    key: '##FIO_CLIENT##',
  },
  {
    title: 'День Рождения',
    key: '##CLIENT_BIRTHDAY##',
  },
  {
    title: 'Текущая дата',
    key: '##CURRENT_DATE##',
  },
  {
    title: 'Название клиники',
    key: '##CLINIC_NAME##',
  },
  {
    title: 'Юр.лицо',
    key: '##LEGAL_ENTITY##',
  },
  {
    title: 'ФИО Доктора',
    key: '##FIO_DOCTOR##',
  },
  {
    title: 'ИНН',
    key: '##INN##',
  },
  {
    title: 'КПП',
    key: '##KPP##',
  },
  {
    title: 'ОГРН',
    key: '##OGRN##',
  },
];

export const CLINIC_EDITOR_TOOLBAR = [
  ['bold', 'italic', 'underline'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [
    { align: '' },
    { align: 'center' },
    { align: 'right' },
    { align: 'justify' },
  ],
  [{ color: [] }],
];

export const REASONS_GETTING_CONTROL_CONSULTATIONS = {
  1: {
    type: 'LittleCharacters',
    title: 'Символы до 15',
  },
  2: {
    type: 'ThirdPerson',
    title: 'Третье лицо',
  },
  3: {
    type: 'FirstConsultation',
    title: 'Первая консультация',
  },
  4: {
    type: 'FirstConsultationOfTheYear',
    title: 'Год с консультации',
  },
  5: {
    type: 'MoreThanDay',
    title: 'Больше суток',
  },
};

export const ACCOUNT_TYPES = [
  {
    id: 0,
    title: 'Выбрать существующую',
  },
  {
    id: 1,
    title: 'Создать новую',
  },
];

export const CLIENT_TYPES = [
  {
    id: 0,
    title: 'Неизвестно',
  },
  {
    id: 1,
    title: 'Android приложение Doctis',
  },
  {
    id: 2,
    title: 'iOS приложение Doctis',
  },
  {
    id: 3,
    title: 'Веб-приложение Doctis для пациента',
  },
  {
    id: 4,
    title: 'CRM',
  },
  {
    id: 5,
    title: 'Веб-приложение Doctis для врача',
  },
  {
    id: 11,
    title: 'Android приложение FoodFessional Generation',
  },
  {
    id: 12,
    title: 'iOS приложение FoodFessional Generation',
  },
  {
    id: 101,
    title: 'Внешний сервис',
  },
  {
    id: 102,
    title: 'СМЭВ',
  },
  {
    id: 103,
    title: 'Диспетчер',
  },
  {
    id: 104,
    title: 'Дистанционный мониторинг',
  },
  {
    id: 13,
    title: 'Теле2',
  },
];

export const BANNER_TYPE = [
  {
    id: 1,
    key: 'promocode',
    title: 'Промокод',
  },
  {
    id: 2,
    key: 'link',
    title: 'Ссылка',
  },
  {
    id: 10,
    key: 'doctors',
    title: 'Врачи',
  },
  {
    id: 11,
    key: 'specializations',
    title: 'Специализации',
  },
];

export const BANNER_CATEGORY = [
  // {
  //   id: 0,
  //   key: 'Promotions',
  //   title: 'Акции',
  // },
  // {
  //   id: 1,
  //   key: 'Doctors',
  //   title: 'Врачи',
  // },
  {
    id: 2,
    key: 'LandingMain',
    title: 'Основной лендинг',
    colored: true,
    order: 1,
  },
  // {
  //   id: 3,
  //   key: 'LandingSecondary',
  //   title: 'Вторичный лендинг',
  //   colored: true,
  //   order: 2,
  // },
  {
    id: 4,
    key: 'AppMain',
    title: 'Дашборд', // все кроме дашборда - цветастые
    colored: false,
    order: 3,
  },
  {
    id: 5,
    key: 'Tmcenters',
    title: 'Телемедицинские центры',
    colored: true,
    order: 4,
  },
  {
    id: 6,
    key: 'Laboratory',
    title: 'Лаборатории',
    colored: true,
    order: 5,
  },
  // {
  //   id: 7,
  //   key: 'MobileApp',
  //   title: 'Мобильное приложение',
  //   colored: true,
  //   order: 6,
  // },
];

export const BANNER_CLIENT_TYPE = [
  {
    id: 2,
    key: 'WebDesktop',
    title: 'Веб-ПК',
  },
  {
    id: 4,
    key: 'Android',
    title: 'Android',
    isMobile: true,
  },
  {
    id: 5,
    key: 'Ios',
    title: 'iOS',
    isMobile: true,
  },
];

export const DOCTOR_INACTIVE_REASONS = [
  {
    id: 0,
    title: 'Новый врач',
  },
  {
    id: 1,
    title: 'Отказался работать',
  },
  {
    id: 2,
    title: 'Отключен за нарушения',
  },
  {
    id: 3,
    title: 'Отключен клиникой',
  },
  {
    id: 4,
    title: 'Уволился',
  },
];

export const TYPE_OF_CONSULTATION_TIME = [
  {
    id: 10,
    value: '10 мин',
  },
  {
    id: 15,
    value: '15 мин',
  },
  {
    id: 20,
    value: '20 мин',
  },
  {
    id: 30,
    value: '30 мин',
  },
  {
    id: 40,
    value: '40 мин',
  },
  {
    id: 45,
    value: '45 мин',
  },
  {
    id: 60,
    value: '60 мин',
  },
];

export const SIGN_TYPES = [
  {
    id: 0,
    title: 'Нет признака',
  },
  {
    id: 1,
    title: 'Мать и дитя',
  },
  {
    id: 2,
    title: 'Foodfessional',
  },
  {
    id: 3,
    title: 'Эвоген',
  },
  {
    id: 4,
    title: 'РТ Здоровье',
  },
];

export const PRICE_BEHAVIOR_TYPES = [
  {
    id: 0,
    title: 'Полис покрывает по лимиту подпрограммы',
  },
  {
    id: 1,
    title: 'Полис всегда покрывает',
  },
  {
    id: 2,
    title: 'Полис никогда не покрывает',
  },
];

export const INSURANCE_TYPES = [
  {
    id: 0,
    title: 'ДМС',
  },
];

export const ACCREDITATION_TYPES = [
  {
    id: 1,
    key: 'Primary',
    title: 'Первичная',
  },
  {
    id: 2,
    key: 'Periodical',
    title: 'Периодическая',
  },
];

export const DOCTORS_EVALUATION_MISTAKE_TYPES = {
  0: 'Впечатление',
  1: 'Структура диалога',
  2: 'Регламент',
  3: 'Протокол',
};

export const QUEUE_CONSULTATION_STATUSES = {
  0: 'Открыт',
  1: 'Отменен',
  2: 'Закрыт',
};

export const PROJECT_TYPES = [
  {
    id: 0,
    title: 'Доктис',
    key: 'Doctis',
    // eslint-disable-next-line
    logo: require('@/assets/images/project-favicons/doctis.png'),
  },
  {
    id: 1,
    title: 'РТ-Доктис',
    key: 'RTHealth',
    // eslint-disable-next-line
  logo: require('@/assets/images/project-favicons/rt-doctis.png'),
  },
];

export const DAYS_OF_WEEK = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];

export const DOCTOR_INFO_LOAD_SLOTS = [
  {
    id: 0,
    value: '1',
    class: 'light-grey',
  },
  {
    id: 1,
    value: '1/1',
    class: 'grey',
  },
  {
    id: 2,
    value: 'Отл',
    class: 'green',
  },
  {
    id: 3,
    value: 'Норм',
    class: 'yellow',
  },
  {
    id: 4,
    value: 'Плох',
    class: 'red',
  },
];
export const DOCTOR_INFO_AMOUNT_SLOTS = [
  {
    id: 0,
    value: '0',
    class: 'white',
  },
  {
    id: 1,
    value: '1',
    class: 'blue-0',
  },
  {
    id: 2,
    value: '2',
    class: 'blue-1',
  },
  {
    id: 3,
    value: '3',
    class: 'blue-2',
  },
  {
    id: 4,
    value: '4',
    class: 'blue-3',
  },
  {
    id: 5,
    value: '5',
    class: 'blue-4',
  },
  {
    id: 6,
    value: '6',
    class: 'blue-5',
  },
  {
    id: 7,
    value: '7',
    class: 'blue-6',
  },
  {
    id: 8,
    value: '8',
    class: 'blue-7',
  },
  {
    id: 9,
    value: '9+',
    class: 'blue-8',
  },
];

export const COMPARSION_OPERATORS_FOR_NUMERIC = [
  {
    value: 'Equal',
    name: 'Равно',
  },
  {
    value: 'NotEqual',
    name: 'Не равно',
  },
  {
    value: 'GreaterThan',
    name: 'Больше чем',
  },
  {
    value: 'LessThan',
    name: 'Меньше чем',
  },
  {
    value: 'GreaterThanOrEqual',
    name: 'Больше Или Равно',
  },
  {
    value: 'LessThanOrEqual',
    name: 'Меньше Или Равно',
  },
];
export const COMPARSION_OPERATORS_FOR_STRING = [
  {
    value: 'Equal',
    name: 'Равно',
  },
  {
    value: 'NotEqual',
    name: 'Не равно',
  },
  {
    value: 'ContainedIn',
    name: 'Содержится в',
  },
];
export const COMPARSION_OPERATORS_FOR_MKB = [
  {
    value: 'ContainedIn',
    name: 'Содержится в',
  },
];
export const COMPARSION_OPERATORS_FOR_ARRAY = [
  {
    value: 'ContainedIn',
    name: 'Содержится в',
  },
];

// a-z
export const FEATURES_FOR_CHAT = {
  activatedOnly: {
    name: 'Только активированные (Галочка заблокирована)',
    url: '/chat',
  },
  addDepartment: {
    name: 'Добавить отделение',
    url: '/chat',
  },
  addCity: {
    name: 'Добавить город',
    url: '/chat',
  },
  appeals: {
    name: 'Обращения',
    url: '/chat',
  },
  attachPolicy: {
    name: 'Прикрепить полис',
    url: '/chat',
  },
  avatar: {
    name: 'Аватарка',
    url: '/chat',
  },
  btnProtocolInChat: {
    name: 'Кнопка Протокол в Чате',
    url: '/chat',
  },
  call: {
    name: 'Вызов',
    url: '/chat',
  },
  chat: {
    name: 'Чат',
    url: '/chat',
  },
  chatWindow: {
    name: 'Окно чата при открытии(Нельзя закрыть)',
    url: '/chat',
  },
  clinicList: {
    name: 'Список клиник',
    url: '/chat',
  },
  clinicMap: {
    name: 'Карта клиник',
    url: '/chat',
  },
  сlinicsGeolocationsSyncButton: {
    name: 'Значок синхронизации Ш.Д.',
    url: '/chat',
  },
  clinicSearch: {
    name: 'Строка поиска клиники',
    url: '/chat',
  },
  createIndemnity: {
    name: 'Создать ГП',
    url: '/chat',
  },
  createReferral: {
    name: 'Создать направление',
    url: '/chat',
  },
  createIndemnityNoPolicy: {
    name: 'Страховые полисы Создать ГП без полиса',
    url: '/chat',
  },
  createReferralNoPolicy: {
    name: 'Страховые полисы. Создать направление без полиса',
    url: '/chat',
  },
  consultations: {
    name: 'Консультации',
    url: '/chat',
  },
  deactivate: {
    name: 'Деактивировать',
    url: '/chat',
  },
  delete: {
    name: 'Удалить',
    url: '/chat',
  },
  deleteAvatarBtn: {
    name: 'Возможность удаления аватарки врача',
    url: '/chat',
  },
  detailInfo: {
    name: 'Детальная информация',
    url: '/chat',
  },
  detailInfoEye: {
    name: 'Детальная информация. Глазик',
    url: '/chat',
  },
  deleteClinic: {
    name: 'Удаление клиники',
    url: '/chat',
  },
  devices: {
    name: 'Устройства',
    url: '/chat',
  },
  doctors: {
    name: 'Врачи',
    url: '/chat',
  },
  mainMenuDevices: {
    name: 'Главное меню. Устройства',
    url: '/chat',
  },
  mainWindow: {
    name: 'Главное окно',
    url: '/chat',
  },
  editDetailInfo: {
    name: 'Детальная информация. Глазик. Редактировать',
    url: '/chat',
  },
  login: {
    name: 'Авторизоваться',
    url: '/chat',
  },
  scheduleAppointment: {
    name: 'Расписание. Записать на прием. Учетная запись. Убрать Создать новую',
    url: '/chat',
  },
  edit: {
    name: 'Редактировать',
    url: '/chat',
  },
  editSchedule: {
    name: 'Расписание. Редактировать',
    url: '/chat',
  },
  exportData: {
    name: 'Выгрузка',
    url: '/chat',
  },
  historyClinic: {
    name: 'История клиники',
    url: '/chat',
  },
  historyIndemnityNoPolicy: {
    name: 'История ГП без полиса',
    url: '/chat',
  },
  historyReferralNoPolicy: {
    name: 'История направлений без полиса',
    url: '/chat',
  },
  importDepartments: {
    name: 'Импорт отделений',
    url: '/chat',
  },
  insurancePolicies: {
    name: 'Страховые полисы',
    url: '/chat',
  },
  labDepartments: {
    name: 'Лабораторные отделения',
    url: '/chat',
  },
  newDoctor: {
    name: 'Новый врач',
    url: '/chat',
  },
  newClinic: {
    name: 'Новая клиника',
    url: '/chat',
  },
  outpatientServices: {
    name: 'Очные оказанные услуги',
    url: '/chat',
  },
  openPolicyLimits: {
    name: 'Открыть лимиты полиса',
    url: '/chat',
  },
  possibilityOfPatientRegistration: {
    name: 'Врачи. Расписание. Запись пациента (кнопка)',
    url: '/chat',
  },
  registerPatientInTheQueue: {
    name: 'Запись пациента в очередь',
    url: '/chat',
  },
  searchByINN: {
    name: 'Строка поиска по ИНН',
    url: '/chat',
  },
  searchDoctorByID: {
    name: 'Поиск по ID врача',
    url: '/chat',
  },
  schedule: {
    name: 'Расписание',
    url: '/chat',
  },
  templateBtn: {
    name: 'Добавить шаблон',
    url: '/chat',
  },
  twoStagesForApprovalOfServices: {
    name: 'Создание обращения из чата. Показывать сразу два этапа для цели «Согласование услуг»',
    url: '/chat',
  },
  typeOfConnection: {
    name: 'Тип связи у врача',
    url: '/chat',
  },
};

export const FEATURES_FOR_DOCTORS = {
  activeDoctors: {
    name: 'Активировать галочку активные врачи',
    url: '/doctors',
  },
  avatar: {
    name: 'Аватарка',
    url: '/doctors',
  },
  deleteAvatarBtn: {
    name: 'Возможность удаления аватарки врача',
    url: '/doctors',
  },
  exportDoctors: {
    name: 'Выгрузка врачей',
    url: '/doctors',
  },
  createDoctor: {
    name: 'Создание врача',
    url: '/doctors',
  },
  searchDoctorsByPhone: {
    name: 'Врачи. Поиск по номеру телефона',
    url: '/doctors',
  },
  seeConsultationBtn: {
    name: 'Видеть кнопку Консультации',
    url: '/doctors',
  },
  seeScheduleBtn: {
    name: 'Видеть кнопку Расписание',
    url: '/doctors',
  },
  doctorSeesThePolicy: {
    name: 'Отображать галочку Врач видит полис',
    url: '/doctors',
  },
  isTrainedForReferralCreation: {
    name: 'Показывать Обучен на создание направлений',
    url: '/doctors',
  },
  isRecommendedDoctor: {
    name: 'Показывать галочку Рекомендованный врач',
    url: '/doctors',
  },
  seeLoginBtn: {
    name: 'Видеть кнопку Авторизоваться',
    url: '/doctors',
  },
  seeDeviceBtn: {
    name: 'Видеть кнопку Устройства',
    url: '/doctors',
  },
  // кнопка для получения подписи для авторизации в виджетах партнеров
  seeSignBtn: {
    name: 'Видеть кнопку Sign',
    url: '/doctors',
  },
  doctorDetailInfo: {
    name: 'Кнопка детальной информации о враче (глазик)',
    url: '/doctors',
  },
  doctorAppealsBtn: {
    name: 'Кнопка обращения к врачу',
    url: '/doctors',
  },
  deleteDoctorBtn: {
    name: 'Кнопка удаления врача',
    url: '/doctors',
  },
  btnProtocolInScheduleForConsultation: {
    name: 'Кнопка Протокол в записи на консультацию',
    url: '/doctors',
  },
  editDoctorModal: {
    name: '(Модальное окно) Редактирование врача',
    url: '/doctors',
  },
  blockEmailField: {
    name: 'Редактирование врача - Заблокировать поле email',
    url: '/doctors',
  },
  blockLoginField: {
    name: 'Редактирование врача - Заблокировать поле Логин',
    url: '/doctors',
  },
  blockPasswordField: {
    name: 'Редактирование врача - Заблокировать поле Пароль',
    url: '/doctors',
  },
  possibilityOfPatientRegistration: {
    name: 'Врачи. Расписание. Запись пациента (кнопка)',
    url: '/doctors',
  },
  typeOfConnection: {
    name: 'Тип связи у врача',
    url: '/doctors',
  },
  typeOfSpecialization: {
    name: 'Детский/взрослый',
    url: '/doctors',
  },
  seeCheckboxTestAndCommissionFee: {
    name: 'Видеть чекбоксы test и комиссионное вознаграждение',
    url: '/doctors',
  },
  hideCheckboxTestAndCommissionFee: {
    name: 'Скрыть чекбоксы test и комиссионное вознаграждение',
    url: '/doctors',
  },
};
// a - z
export const FEATURES_FOR_PATIENTS = {
  abilityToEditIndemnuty: {
    name: 'Возможность редактировать ГП',
    url: '/patients',
  },
  attachPolicy: {
    name: 'Возможность прикрепить полис',
    url: '/patients',
  },
  createIndemnity: {
    name: 'Возможность создать ГП',
    url: '/patients',
  },
  createIndemnityNoPolicy: {
    name: 'Полисы. Создать ГП без полиса',
    url: '/patients',
  },
  createReferral: {
    name: 'Возможность создать направление',
    url: '/patients',
  },
  createReferralNoPolicy: {
    name: 'Полисы. Создать направление без полиса',
    url: '/patients',
  },
  detailInfoToChat: {
    name: 'Пациент. Информация об обращении. В Чат',
    url: '/patients',
  },
  detailInfoChatIcon: {
    name: 'Пациент. Информация об обращении. Иконка Чат',
    url: '/patients',
  },
  detailInfoHistoryIndemnity: {
    name: 'Пациент детальная информация. История ГП',
    url: '/patients',
  },
  editCommentInPolicy: {
    name: 'Полисы. Редактировать комментарий в полисе',
    url: '/patients',
  },
  hideCommentInDetailInfo: {
    name: 'Пациент. Информация о пациенте. Скрыть комментарий',
    url: '/patients',
  },
  hideBtnOpenPolicyLimits: {
    name: 'Скрыть кнопку Открыть лимиты полиса',
    url: '/patients',
  },
  hideCallingBtnInAppeals: {
    name: 'Обращения. Информация об обращении. Скрыть кнопку позвонить',
    url: '/patients',
  },
  hideChangeHistoryBtnInAppeals: {
    name: 'Обращения. Информация об обращении. Скрыть кнопку История изменений',
    url: '/patients',
  },
  hideCommentInCardPatient: {
    name: 'Скрыть комментарий в карточке пациента',
    url: '/patients',
  },
  hideCommentCuratorDoctor: {
    name: 'Обращения. Информация об обращении. Скрыть в Инфо о согл данных - Комментарий врача-куратора',
    url: '/patients',
  },
  hideCommentOperator: {
    name: 'Обращения. Информация об обращении. Скрыть в Инфо о согл данных - Комментарий для оператора',
    url: '/patients',
  },
  hideHistoryChangesOfPatient: {
    name: 'Скрыть Историю изменений пациента',
    url: '/patients',
  },
  hideHistoryOfPolicy: {
    name: 'Страховые полисы. Скрыть Историю полиса',
    url: '/patients',
  },
  hideReasonForRefusal: {
    name: 'Обращения. Информация об обращении. Скрыть в Инфо о согл данных - Причина отказа',
    url: '/patients',
  },
  hideSendSmsBtnInAppeals: {
    name: 'Обращения. Информация об обращении. Скрыть кнопку отправить смс',
    url: '/patients',
  },
  hidePersonResponsibleForApproval: {
    name: 'Обращения. Информация об обращении. Скрыть в Инфо о согл данных - Ответственного за согласование',
    url: '/patients',
  },
  hidePersonResponsibleForRequest: {
    name: 'Обращения. Информация об обращении. Скрыть ответственного за обращение',
    url: '/patients',
  },
  hidePolicyInformationBlock: {
    name: 'Скрыть блок информации о полисе',
    url: '/patients',
  },
  historyReferralNoPolicy: {
    name: 'Полисы. История направления без полиса',
    url: '/patients',
  },
  historyIndemnityNoPolicy: {
    name: 'Полисы. История ГП без полиса',
    url: '/patients',
  },
};
// a - z
export const FEATURES_FOR_LABORATORY = {
  addBranch: {
    name: 'Добавить отделение',
    url: '/laboratory-branches',
  },
  deactivateBranch: {
    name: 'Деактивировать отделение',
    url: '/laboratory-branches',
  },
};
//  a - z
export const FEATURES_FOR_CLINICS = {
  addCityButton: {
    name: 'Кнопка добавления города',
    url: '/clinics',
  },
  createClinic: {
    name: 'Создание клиники',
    url: '/clinics',
  },
  deleteClinic: {
    name: 'Удаление клиники',
    url: '/clinics',
  },
  hideDisplayOfClinicFeatures: {
    name: 'Скрыть отображение особенности клиники',
    url: '/clinics',
  },
  historyClinic: {
    name: 'История клиники',
    url: '/clinics',
  },
  searchField: {
    name: 'Поисковое поле',
    url: '/clinics',
  },
};
export const FEATURES_FOR_SCHEDULE = {
  possibilityOfRegistration: {
    name: 'Расписание врачей. Возможность записи на приём.',
    url: '/schedule',
  },
  scheduleAppointment: {
    name: 'Расписание. Записать на прием. Учетная запись. Убрать Создать новую',
    url: '/schedule',
  },
  scheduleHistory: {
    name: 'История изменений шаблона расписания',
    url: '/schedule',
  },
  showBtnRescheduleConsultation: {
    name: 'Показывать кнопку перенести консультацию в расписании',
    url: '/schedule',
  },
  showBtnRescheduleConsultationInDoctors: {
    name: 'Показывать кнопку перенести консультацию в разделе Расписание врачей',
    url: '/schedule',
  },
};
export const FEATURES_FOR_CONSULTATIONS = {
  reopenСonsultation: {
    name: 'Возможность переоткрыть консультацию',
    url: '/consultations',
  },
  deletingConsultation: {
    name: 'Удаление консультации',
    url: '/consultations',
  },
  transferСonsultation: {
    name: 'Перенос консультации',
    url: '/consultations',
  },
  historyOfChanges: {
    name: 'История изменений',
    url: '/consultations',
  },
  historyOfConsultationReopenings: {
    name: 'История переоткрытия консультации',
    url: '/consultations',
  },
  chatConsultation: {
    name: 'Чат консультации',
    url: '/consultations',
  },
  protocol: {
    name: 'Протокол',
    url: '/consultations',
  },
};
export const FEATURES_FOR_APPEALS = {
  hideChangeHistoryBtn: {
    name: 'Скрыть кнопку История изменений',
    url: '/appeals',
  },
  hideDataReconciliationInfoCommentDoctorCurator: {
    name: 'Обращения. Информация о согласовании данных - Скрыть комментарий для врача-куратора',
    url: '/appeals',
  },
  hideDataReconciliationInfoCommentOperator: {
    name: 'Обращения. Информация о согласовании данных - Скрыть комментарий для оператора',
    url: '/appeals',
  },
  hideDataReconciliationInfoDiagnos: {
    name: 'Обращения. Информация о согласовании данных - Скрыть диагноз',
    url: '/appeals',
  },
  hideDataReconciliationInfoLinkedCaseId: {
    name: 'Обращения. Информация о согласовании данных - Скрыть ID связанного обращения',
    url: '/appeals',
  },
  hideDataReconciliationInfoResponsibleForApproval: {
    name: 'Обращения. Информация о согласовании данных - Скрыть ответственный за согласование',
    url: '/appeals',
  },
  hideFiltersBtn: {
    name: 'Скрыть кнопку фильтры',
    url: '/appeals',
  },
  hidePersonResponsibleForAppeal: {
    name: 'Скрыть ответственного за обращение',
    url: '/appeals',
  },
  hideResponsibleInEditAppealInApproval: {
    name: 'Скрыть отображение в редактируемом обращении - Ответственный',
    url: '/appeals',
  },
  hideDiagnosInEditAppealInApproval: {
    name: 'Скрыть отображение в редактируемом обращении в согласовании - Диагноз',
    url: '/appeals',
  },
  hideReasonForRefusalInEditAppealInApproval: {
    name: 'Скрыть отображение в редактируемом обращении в согласовании - Причина отказа',
    url: '/appeals',
  },
  hideCommentDoctorInEditAppealInApproval: {
    name: 'Скрыть отображение в редактируемом обращении в согласовании - Комментарий врача',
    url: '/appeals',
  },
  hideFilesInEditAppealInApproval: {
    name: 'Скрыть отображение в редактируемом обращении в согласовании - Файлы',
    url: '/appeals',
  },
  hideResponsibleCoordinationInEditAppealInApproval: {
    name: 'Скрыть отображение в редактируемом обращении в согласовании - Ответственный в блоке согласования',
    url: '/appeals',
  },
  hideCommentOperatorInEditAppealInApproval: {
    name: 'Скрыть отображение в редактируемом обращении в согласовании - Комментарий для оператора',
    url: '/appeals',
  },
  removeCreateIndemnityBtn: {
    name: 'Убрать создание ГП в обращениях',
    url: '/appeals',
  },
  removeCreateReferralBtn: {
    name: 'Убрать создание Направления в обращениях',
    url: '/appeals',
  },
};
export const FEATURES_FOR_INSURANCE_COMPANIES = {
  btnAddingCompany: {
    name: 'Добавление компании',
    url: '/insurance-companies',
  },
  btnCheckingRegistries: {
    name: 'Кнопка Проверка реестров от СК',
    url: '/insurance-companies',
  },
  btnExport: {
    name: 'Экспорт',
    url: '/insurance-companies',
  },
  btnImportQueue: {
    name: 'Кнопка Очередь импорта',
    url: '/insurance-companies',
  },
  hideBtnShowHidden: {
    name: 'Скрыть кнопку показать скрытые',
    url: '/insurance-companies',
  },
  notClickedBtnImport: {
    name: 'Не кликабельная кнопка Импорт',
    url: '/insurance-companies',
  },
  prohibitionOfActionsWithSubroutines: {
    name: 'Запрет действий с подпрограммами',
    url: '/insurance-companies',
  },
};
export const FEATURES_FOR_LEGAL_ENTITIES = {
  abilityCreateLegalEntity: {
    name: 'Создать юр лицо',
    url: '/legal-entities',
  },
  hideBtnEdit: {
    name: 'Скрыть отображение кнопки Редактировать',
    url: '/legal-entities',
  },
  hideBtnExport: {
    name: 'Скрыть отображение кнопки Экспорт реестров',
    url: '/legal-entities',
  },
  hideBtnDetails: {
    name: 'Скрыть отображение кнопки Подробнее',
    url: '/legal-entities',
  },
  hideBtnDelete: {
    name: 'Скрыть отображение кнопки Удалить',
    url: '/legal-entities',
  },
  hideBtnIsInfoShow: {
    name: 'Скрыть отображение привязанных клиник',
    url: '/legal-entities',
  },
};
export const ARR_LIST_ID_FOR_CERTAIN_POLIC = [326, 445, 456];

export const USER_SIP_STATUS = {
  0: 'На линии',
  1: 'Перерыв',
  2: 'Обзвон',
  3: 'Не беспокоить ',
  4: 'Собрание',
  5: 'Пост обработка',
};

export const FINAL_STATUS_TYPE = [
  {
    id: 0,
    title: 'Оформлен',
    value: 'Issued',
  },
  {
    id: 1,
    title: 'Не дозвонились',
    value: 'NotReached',
  },
  {
    id: 2,
    title: 'Отказ',
    value: 'Refusal',
  },
];

export const TAG_STATUS_TYPE = {
  MONITORING: 'monitoring',
  CALL: 'call',
  FINAL_STATUS: 'finalStatus',
};

export const MONITORING_STATUS_TYPE = [
  { id: 0, title: 'Отказ?', value: 'Refusal' },
  { id: 1, title: 'Ждет помощи ВТБ', value: 'WaitingForHelp' },
  { id: 2, title: 'Самостоятельно', value: 'Individually' },
  { id: 3, title: 'Оформили', value: 'Issued' },
  { id: 4, title: 'Категоричный отказ', value: 'VtbProblem' },
  { id: 5, title: 'НДЗ', value: 'NotReached' },
  { id: 6, title: 'Позвонить', value: 'CallIsRequired' },
  { id: 7, title: 'Уточнить', value: 'Clarify' },
];

export const REMOTE_MONITORING_STATUS_STRING_FULL = [
  {
    title: 'Активен',
    value: 'Active',
  },
  {
    title: 'Завершен',
    value: 'Concluded',
  },
  {
    title: 'Отказ',
    value: 'Refused',
  },
  {
    title: 'Отменен',
    value: 'Cancelled',
  },
  {
    title: 'Приостановлен',
    value: 'Suspended',
  },
  {
    title: 'Создан',
    value: 'Created',
  },
];

export const REMOTE_MONITORING_STATUS = {
  ACTIVE: 0, // Активе
  CONCLUDED: 1, // Завершен
  REFUSED: 2, // Отказ от мониторинга
  CANCELED: 3, // Отменен
  SUSPENDED: 4, // Приостановлен
};

export const INITIATORS = [
  {
    id: 0,
    name: 'Врач',
  },
  {
    id: 1,
    name: 'Пациент',
  },
  {
    id: 2,
    name: 'Техническая ошибка',
  },
];

export const RESERVATION_STATUSES = [
  {
    id: 0,
    title: 'Не выбрано',
    value: null,
  },
  {
    id: 1,
    title: 'Ожидает подтверждения',
    value: 'Booked',
  },
  {
    id: 2,
    title: 'Подтвержденный',
    value: 'Confirmed',
  },
  {
    id: 3,
    title: 'Отмененный',
    value: 'Canceled',
  },
];

export const GLUING_TYPES = {
  partnerService: {
    id: 0,
    title: 'Услуга партнера',
  },
  marketService: {
    id: 1,
    title: 'Услуга маркета',
  },
};

export const GLUNING_SERVICE_STATUSES = [
  {
    value: true,
    title: 'Да',
  },
  {
    value: false,
    title: 'Нет',
  },
];

export const GLUNING_SERVICE_MAPPABLES = [
  {
    value: true,
    title: 'Да',
  },
  {
    value: false,
    title: 'Нет',
  },
];

export const NEED_LEGAL_PERSON_STATUSES = [
  {
    value: true,
    title: 'Да',
  },
  {
    value: false,
    title: 'Нет',
  },
];

// PromocodeEditModalNew (a-z)
export const COUNT_TYPES = [
  { alias: 'limitless', title: 'Не лимитированное' },
  { alias: 'multiple', title: 'Лимитированное' },
];

export const DISCOUNT_TYPE = [
  { alias: 'fixed', title: 'Фиксированный' },
  { alias: 'dynamic', title: 'Динамический' },
];

export const NUMBER_OF_APPLICATIONS = [
  { alias: 'single', title: 'Одноразовый' },
  { alias: 'multiple', title: 'Многоразовый' },
  { alias: 'limitless', title: 'Не лимитированный' },
];

export const PROMO_CODES_TYPES = [
  // { alias: 'absolute', title: 'Абсолютный' },
  { alias: 'fixed', title: 'Фиксированный' },
  { alias: 'perc', title: 'Процентный' },
];

export const PROMO_CODE_VALIDITY_PREIOD = [
  { alias: 'actual', title: 'С ограниченным сроком' },
  { alias: 'limitless', title: 'Бессрочный промокод' },
];

export const SIGN_OF_DEPOSIT = [
  { id: 0, title: 'Направлен в клинику для сверки' },
  { id: 1, title: 'Выдан пациенту' },
];

export const SPEC_TYPES = [
  { alias: 'none', title: 'Без привязки' },
  { alias: 'use', title: 'С привязкой к специализации' },
  { alias: 'doc', title: 'С привязкой к врачу' },
];

// InsuranceSubprogramCreatePageDMS

export const DOC_TYPES = [
  'documentForClients',
  'detailedDescription',
  'policyTemplate',
  'additionalInformationForClients',
];

export const PROGRAMS = [
  {
    id: 0,
    title: 'Выбрать существующую программу',
  },
  {
    id: 1,
    title: 'Создать новую программу',
  },
];

export const POLICY_PUROISES = [
  {
    id: 1,
    title: 'Телемедицина',
  },
  {
    id: 2,
    title: 'Диагностика',
  },
  {
    id: 3,
    title: 'Госпитализация',
  },
  {
    id: 4,
    title: 'Лечение',
  },
  {
    id: 5,
    title: 'Роды',
  },
  {
    id: 6,
    title: 'Ведение беременности',
  },
];

export const PRODUCT_GROUP = [
  {
    id: 1,
    title: 'ДМС',
  },
  {
    id: 2,
    title: 'Коробочная версия',
  },
  {
    id: 3,
    title: 'Телемедицина',
  },
];

export const ACTIVATION_TYPES = [
  {
    id: 0,
    title: 'По номеру полиса',
  },
  {
    id: 1,
    title: 'По страхователю',
  },
  {
    id: 2,
    title: 'Автоматическая активация',
  },
];

export const LIMIT_TYPES = [
  {
    id: 1,
    title: 'Общий лимит',
  },
  {
    id: 0,
    title: 'Индивидуальный',
  },
  {
    id: 2,
    title: 'Общий + индивидуальный',
  },
];

export const PRICE_CATEGORIES = [
  {
    id: 0,
    title: 'ДМС',
  },
  {
    id: 1,
    title: 'Индивидуальный',
  },
  {
    id: 2,
    title: 'Продукт в коробке',
  },
  {
    id: 3,
    title: 'Телемед',
  },
];
