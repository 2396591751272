import SipUserApi from '@/services/remote-monitoring/remoteMonitoring.api';

export default class RemoteMonitoringService {
  constructor($axios) {
    this.api = new SipUserApi($axios);
  }

  async getMonitoringTableData(params) {
    const { data, headers } = await this.api.getMonitoringTableData(
      params,
    );
    return { data, headers };
  }

  async smsList(payload) {
    const { data, totalCount } = await this.api.smsList(payload);
    return { data, totalCount };
  }

  async getRemoteMonitoringTemplatesList() {
    const data = await this.api.getRemoteMonitoringTemplatesList();
    return data;
  }

  async fetchSpecialization(search = '') {
    const data = await this.api.fetchSpecialization(search);
    return data;
  }

  async removePatientMonitorings(ids) {
    const data = await this.api.removePatientMonitorings(ids);
    return data;
  }
}
