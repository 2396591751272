import NProgress from 'nprogress/nprogress';
import { format, parseISO } from '@evd3v/date-fns';

import * as types from '@/store/types';
import {
  removeNamespaces, showMessage, convertObjToQueryParams, saveFile,
} from '@/helpers/utils';
import { api } from '@/helpers/api';

const localTypes = removeNamespaces(types);

export default {
  async [localTypes.DOCTOR_AUTH_TOKEN_FETCH](ctx, personId) {
    NProgress.start();

    try {
      const { data: token } = await api.post(`v2/doctors/${personId}/auth`);

      return token;
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.DOCTOR_FETCH](ctx, id) {
    NProgress.start();

    try {
      const { data: doctor } = await api.get(`v2/doctors/${id}`);

      return doctor;
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось получить информацию о враче',
      });
    } finally {
      NProgress.done();
    }
  },

  async [localTypes.GET_DOCTOR_SERVICES](ctx, doctorName) {
    NProgress.start();

    try {
      const { data } = await api.get('v2/doctors/get-services', {
        params: { doctorName },
      });

      return data;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.DOCTOR_CREATE](ctx, doctor) {
    NProgress.start();

    try {
      await api.post('v2/doctors', doctor);

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Врач успешно добавлен!',
      });
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.DOCTOR_UPDATE](ctx, doctor) {
    NProgress.start();

    try {
      await api.put('v2/doctors', doctor);

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Врач успешно изменен!',
      });
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.DOCTOR_DELETE](ctx, { id, reestablish = false }) {
    NProgress.start();

    try {
      await api.delete(`v2/doctors/${id}`);

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: reestablish ? 'Врач успешно восстановлен!' : 'Врач успешно удален!',
      });
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.DOCTORS_FETCH]({ commit }, {
    skip,
    query,
    take,
    clear,
    doctorSpecializationId,
    medicalInsuranceType,
    regionId,
    id,
    save,
    isDeleted,
    phoneNumber,
    audio,
    video,
    forAdults,
    forChildren,
    isActive = true,
  } = {}) {
    NProgress.start();

    if (save && clear) {
      commit(localTypes.DOCTORS_SET, []);
    }

    try {
      const params = new URLSearchParams();

      if (query) params.append('search', query);
      if (phoneNumber) params.append('PhoneNumber', phoneNumber);
      if (skip) params.append('skip', skip);
      if (take) params.append('take', take);
      if (isDeleted) params.append('isDeleted', isDeleted);
      if (typeof isActive === 'boolean')params.append('isActive', isActive);
      if (doctorSpecializationId || doctorSpecializationId === 0) params.append('doctorSpecializationId', doctorSpecializationId);
      if (medicalInsuranceType || medicalInsuranceType === 0) params.append('medicalInsuranceType', medicalInsuranceType);
      if (regionId || regionId === 0) params.append('regionId', regionId);
      if (id || id === 0) params.append('id', id);
      if (audio) params.append('audio', audio);
      if (video) params.append('video', video);
      if (forAdults) params.append('forAdults', forAdults);
      if (forChildren) params.append('forChildren', forChildren);

      const { data: doctors } = await api.get('v2/doctors/find', {
        params,
      });

      if (save) {
        commit(localTypes.DOCTORS_ADD, doctors);
      }

      return doctors;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.DOCTORS_CONSULTATIONS_FETCH]({ commit }, {
    skip,
    take,
    doctorId,
  }) {
    NProgress.start();

    try {
      const params = new URLSearchParams();

      params.append('skip', skip);
      params.append('take', take);
      const { data: consultations } = await api.get(`v2/doctors/${doctorId}/consultations`, {
        params,
      });

      commit(localTypes.DOCTORS_CONSULTATIONS_ADD, consultations);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.SPECIALIZATIONS_SEARCH](ctx, query) {
    NProgress.start();

    try {
      const params = new URLSearchParams();

      params.append('search', query);
      const { data: specializations } = await api.get('v2/specializations/find', {
        params,
      });

      return specializations
        .filter((spec) => !spec.isDeleted)
        .map((specialization) => ({ id: specialization.id, name: specialization.title }));
    } catch (e) {
      console.warn(e);
      return [];
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.DOCTOR_UNLOAD_FETCH](ctx, filter) {
    NProgress.start();
    const params = convertObjToQueryParams(filter);

    try {
      const { data } = await api.get('v2/doctors/report', {
        params,
        responseType: 'blob',
      });

      let title = '';

      if (filter.clinicName) {
        title = filter.clinicName;
      } else {
        title = `${format(parseISO(filter.startDate), 'dd/MM/yyyy')} - ${format(parseISO(filter.endDate), 'dd/MM/yyyy')}`;
      }

      saveFile(data, { name: `Выгрузка по врачам ${title}.xlsx` });
    } catch (e) {
      console.warn(e);
      showMessage({ type: 'error', message: 'Не удалось выгрузить врачей' });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.DOCTOR_FETCH_REGIONS]() {
    NProgress.start();

    try {
      const { data: regions } = await api.get('v2/regions/list');

      return regions;
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось получить информацию о регионах',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.DOCTOR_FETCH_SPECIALITIES]() {
    NProgress.start();
    try {
      const { data } = await api.get('v2/doctors/specialities');

      return data;
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось получить информацию о специальностях',
      });
    } finally {
      NProgress.done();
    }
  },

  async [localTypes.DOCTOR_AVATAR_GET](ctx, { avatarCloudKey }) {
    NProgress.start();

    try {
      const { data } = await api.get(`v2/doctors/get-avatar?key=${avatarCloudKey}`, { responseType: 'blob' });

      return data;
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },

  async [localTypes.DOCTOR_AVATAR_DELETE](ctx, { doctorId }) {
    NProgress.start();

    try {
      await api.delete(`v2/doctors/delete-avatar?doctorId=${doctorId}`);
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Аватарка врача успешно удалена.',
      });
    } catch (e) {
      console.warn(e);
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось удалить аватарку врача.',
      });
      throw e;
    } finally {
      NProgress.done();
    }
  },

  async [localTypes.DOCTOR_AVATAR_UPLOAD](ctx, { doctorId, file }) {
    NProgress.start();
    const formData = new FormData();
    formData.append('file', file);

    try {
      const { data } = await api.post(`v2/doctors/upload-avatar?doctorId=${doctorId}`, formData, {
        'Content-Type': 'multipart/form-data',
      });

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Аватарка врача успешно сохранена.',
      });

      return data;
    } catch (e) {
      console.warn(e);
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось сохранить аватарку врача.',
      });
      throw e;
    } finally {
      NProgress.done();
    }
  },

  async [localTypes.DOCTOR_FILE_PUT](_, files) {
    NProgress.start();

    const formData = new FormData();

    files.forEach((file) => formData.append('files', file));

    try {
      const { data } = await api.post('v2/doctors/put-files', formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      });

      return data;
    } catch (e) {
      console.warn(e);
    } finally {
      NProgress.done();
    }
  },

  async [localTypes.DOCTOR_FILE_DOWNLOAD](ctx, { fileKey }) {
    NProgress.start();

    try {
      const { data } = await api.get(`v2/doctors/download-file?key=${fileKey}`, { responseType: 'blob' });

      saveFile(data);
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },

  async [localTypes.DOCTORS_EVALUATION_FETCH](
    {
      commit,
      state,
    },
    {
      clear,
      limit,
      doctorName,
      consultationId,
      closeDateFrom,
      closeDateTo,
      isAgree,
      evaluation,
    },
  ) {
    NProgress.start();

    if (state.isLoading) return;

    commit(localTypes.DOCTORS_EVALUATION_IS_LOADING_SET, true);
    commit(localTypes.DOCTORS_EVALUATION_IS_ALL_ITEMS_LOADED_SET, false);

    if (clear) {
      commit(localTypes.DOCTORS_EVALUATION_ITEMS_SET, []);
      commit(localTypes.DOCTORS_EVALUATION_PAGE_SET, 0);
    }

    const skip = clear ? 0 : state.doctorsEvaluation.page * limit;

    try {
      const params = new URLSearchParams();

      params.append('skip', skip);
      params.append('take', limit);
      if (doctorName) params.append('doctorName', doctorName);
      if (consultationId) params.append('consultationId', consultationId);
      if (closeDateFrom) params.append('closeDateFrom', new Date(closeDateFrom).toISOString());
      if (closeDateTo) params.append('closeDateTo', new Date(closeDateTo).toISOString());
      if (isAgree != null) params.append('isAgree', isAgree);
      if (evaluation) params.append('state', evaluation);

      const { data: { data } } = await api.get('v3/doctor-evaluation/search', {
        params,
      });

      commit(localTypes.DOCTORS_EVALUATION_ITEMS_ADD, data);
      commit(localTypes.DOCTORS_EVALUATION_PAGE_SET, state.doctorsEvaluation.page + 1);

      if (data?.length === 0) {
        commit(localTypes.DOCTORS_EVALUATION_IS_ALL_ITEMS_LOADED_SET, true);
      }
    } catch (e) {
      commit(localTypes.DOCTORS_EVALUATION_IS_ALL_ITEMS_LOADED_SET, true);

      console.warn(e);
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: e,
      });
    } finally {
      NProgress.done();
      commit(localTypes.DOCTORS_EVALUATION_IS_LOADING_SET, false);
    }
  },

  async [localTypes.DOCTORS_EVALUATION_MISTAKES_FETCH](ctx, doctorId) {
    NProgress.start();

    try {
      const { data: { data } } = await api.get(`v3/doctor-evaluation/mistakes?doctorId=${doctorId}`);

      return data;
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },

  async [localTypes.DOCTORS_EVALUATION_EVALUATE](_, params) {
    NProgress.start();
    try {
      await api.post('v3/doctor-evaluation/evaluate', params);

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Оценка консультации врача успешно сохранена.',
      });
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось сохранить оценку консультации врача.',
      });
      throw e;
    } finally {
      NProgress.done();
    }
  },

  async [localTypes.DOCTORS_EVALUATION_DELETE](ctx, consultationId) {
    NProgress.start();

    try {
      await api.delete(`v3/doctor-evaluation/delete-evaluation?consultationId=${consultationId}`);

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Оценка консультации удалена!',
      });
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.DOCTORS_LIST_BY_SPECIALIZATION](ctx, id) {
    NProgress.start();
    try {
      const { data } = await api.get(`v2/doctors/find?DoctorSpecializationId=${id}`);

      return data;
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось получить информацию о докторах',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.DOCTOR_SCHEDULE_FETCH](ctx, { specializationId } = {}) {
    NProgress.start();
    try {
      const params = new URLSearchParams();

      if (specializationId) params.append('specializationId', specializationId);
      params.append('timezone', new Date().getTimezoneOffset() / -60);
      const { data: { data } } = await api.get('v3/schedule/summary', { params });
      return data;
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось получить информацию о сводном расписании',
      });
    } finally {
      NProgress.done();
    }
  },
};
