<template>
  <div class="consultation-item">
    <div class="crm-row">
      <div class="crm-id">
        ID: {{ consultation.id }}
      </div>
      <div class="crm-date">
        {{ date }}
      </div>
    </div>
    <div class="specialization">
      {{ specialization }}
    </div>
    <div class="doctor">
      {{ doctorFullname }}
    </div>
    <div
      class="crm-status"
    >
      <span>
        Статус:
      </span>
      <span
        class="status-text"
        :class="statusColor"
      >
        {{ statusText }}
      </span>
    </div>
  </div>
</template>

<script>
import { parseISO, format } from '@evd3v/date-fns';

export default {
  name: 'PatientDetailConsultationsItem',
  props: {
    consultation: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      consultationStatuses: {
        success: [0],
        pending: [4, 1, 3],
        close: [9, 8, 6, 7, 2],
      },
      statuses: {
        0: {
          title: 'Новые',
          value: 'new',
        },
        1: {
          title: 'В работе',
          value: 'work-in-progress',
        },
        2: {
          title: 'Закрытые',
          value: 'closed',
        },
        3: {
          title: 'Консилиум',
          value: 'consilium',
        },
        4: {
          title: 'Ожидание оплаты',
          value: 'waiting-payment',
        },
        6: {
          title: 'Отмена',
          value: 'closed',
        },
        7: {
          title: 'Ожидание оплаты истекло',
          value: 'waiting-payment-timeout',
        },
        8: {
          title: 'Отменено пациентом',
          value: 'closed',
        },
        9: {
          title: 'Отменено доктором',
          value: 'closed',
        },
        11: {
          title: 'Ошибочный статус',
          value: 'closed',
        },
        '-11': {
          title: 'Ошибочный статус',
          value: 'closed',
        },
      },
    };
  },
  computed: {
    date() {
      return format(parseISO(this.consultation.createDate), 'dd.MM.yyyy');
    },
    doctorFullname() {
      const { middleName, firstName, lastName } = this.consultation.doctor;

      return `${lastName || ''} ${firstName || ''} ${middleName || ''}`;
    },
    statusColor() {
      if (this.consultationStatuses.success.includes(this.consultation.status)) return 'green';
      if (this.consultationStatuses.pending.includes(this.consultation.status)) return 'yellow';
      if (this.consultationStatuses.close.includes(this.consultation.status)) return 'red';

      return '';
    },
    statusText() {
      // return this.statuses[this.consultation.status].title;
      return this.consultation.statusText || this.statuses[this.consultation.status].title;
    },
    specialization() {
      return this.consultation?.doctorSpecialization?.name;
    },
  },
};
</script>

<style lang="scss" scoped>
.consultation-item {
  cursor: pointer;
  padding: 20px 10px;
  margin: 0 -10px;
  width: 100%;
  border-bottom: 1px solid #E0E0E0;

  &.active {
    background: #6E88F3;
    color: #ffffff;

    .specialization {
      color: #ffffff;
    }
    .crm-status {
      color: #ffffff;
    }
    .status-text {
      color: #ffffff!important;
    }
  }

  .crm-row {
    display: flex;
    justify-content: space-between;
  }

  .crm-id, .crm-date {
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
  }
  .specialization {
    font-size: 14px;
    line-height: 18px;
    margin-top: 10px;
    color: #4B62C1;
  }
  .doctor {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-top: 5px;
  }

  .crm-status {
    margin-top: 10px;
    font-size: 14px;
    line-height: 18px;
    color: #908F8D;
  }

  .status-text {
    font-weight: 500;
    &.green {
      color: $green;
    }
    &.yellow {
      color: #F2994A;
    }
    &.red {
      color: $red;
    }
  }
}
</style>
