// eslint-disable-next-line
export const MODALS = {
  // Accept
  AGREE_MODAL: () => import('@/components/domains/AgreeModal.vue'),

  // Appeals
  APPEAL_TEMPLATE_MODAL: () => import('@/components/domains/appeals/AppealTemplatesModal/index.vue'),
  APPEAL_TEMPLATE_EDIT_MODAL: () => import('@/components/domains/appeals/AppealTemplateEditModal/index.vue'),
  APPEAL_TEMPLATE_CHAT_MODAL: () => import('@/components/domains/appeals/AppealTemplatesChatModal/index.vue'),
  APPEAL_TEMPLATE_EDIT_CHAT_MODAL: () => import('@/components/domains/appeals/AppealTemplatesEditChatModal/index.vue'),
  APPEAL_BEFORE_CLOSING_MODAL: () => import('@/components/domains/appeals/CreatingAppealBeforeClosingModal.vue'),
  APPEAL_PATIENT_CHAT_MODAL: () => import('@/components/domains/appeals/PatientAppealChatModal.vue'),
  APPEAL_HISTORY_MODAL: () => import('@/components/domains/appeals/AppealHistoryModal.vue'),
  APPEAL_EXPORT_MODAL: () => import('@/components/domains/appeals/AppealsExportModal.vue'),
  APPEAL_EDITOR_MODAL: () => import('@/components/domains/appeals/AppealEditorModal.vue'),
  APPEAL_EDITOR_NEW_MODAL: () => import('@/components/domains/appeals/AppealEditorModalNew/index.vue'),
  PATIENT_APPEALS_MODAL: () => import('@/components/domains/appeals/PatientAppealsModal.vue'),

  // Appointement
  PATIENT_APPOINTMENT_MODAL: () => import('@/components/domains/patient/PatientAppointmentModal.vue'),

  // Analysis
  PREPARING_ANALYSIS_CREATE_MODAL: () => import('@/components/domains/analysis/PreparingAnalysisCreateModal.vue'),
  PREPARING_ANALYSIS_EDIT_MODAL: () => import('@/components/domains/analysis/PreparingAnalysisEditModal.vue'),
  PREPARING_ANALYSIS_DELETE_MODAL: () => import('@/components/domains/analysis/PreparingAnalysisDeleteModal.vue'),

  // Branches
  ADD_BRANCH_MODAL: () => import('@/components/domains/laboratories/branches/AddBranchModal.vue'),
  EDIT_BRANCH_MODAL: () => import('@/components/domains/laboratories/branches/EditBranchModal.vue'),

  // CHAT
  CHAT_MODAL: () => import('@/components/domains/chat/ChatModal.vue'),
  CHAT_MOVE_APPEAL_MODAL: () => import('@/components/domains/chat/ChatMoveAppealModal.vue'),
  CHAT_APPEAL_DELETE_MODAL: () => import('@/components/domains/chat/ChatAppealDeleteModal.vue'),
  CHAT_CONSULTATION_MODAL: () => import('@/components/domains/chat/ConsultationChatModal.vue'),
  // !!! why path is not include folder domains?
  MESSAGE_EDIT_MODAL: () => import('@/components/Chat/Modals/MessageEditModal'),

  // Cities
  CITY_CREATE_MODAL: () => import('@/components/domains/laboratories/cities/CityCreateModal.vue'),
  CITY_EDIT_MODAL: () => import('@/components/domains/laboratories/cities/CityEditModal.vue'),
  CITY_DELETE_MODAL: () => import('@/components/domains/laboratories/cities/DeleteCityModal.vue'),

  // Clinics
  CLINIC_HISTORY_MODAL: () => import('@/components/domains/clinics/ClinicHistoryModal.vue'),

  // Consultations
  CONSULTATION_TRANSFER_MODAL: () => import('@/components/domains/consultations/ConsultationTransferModal.vue'),
  CONSULTATION_CANCEL_MODAL: () => import('@/components/domains/consultations/ConsultationCancelModal.vue'),
  CONSULTATION_DELETING_MODAL: () => import('@/components/domains/consultations/ConsultationDeletingModal.vue'),

  // Doctors
  DOCTORS_MODAL: () => import('@/components/domains/doctors/DoctorsModal.vue'),
  DOCTOR_EDIT_MODAL: () => import('@/components/domains/doctors/DoctorEditModal.vue'),
  DOCTOR_SCHEDULE_INFO_MODAL: () => import('@/components/domains/doctors/DoctorScheduleInfoModal.vue'),
  DOCTOR_HISTORY_OF_CHANGES_MODAL: () => import('@/components/domains/doctors/DoctorHistoryOfChangesModal.vue'),

  // Files upload
  FILES_UPLOAD_MODAL: () => import('@/components/domains/FilesUploadModal.vue'),

  // Indemnity
  INDEMNITY_HISTORY_MODAL: () => import('@/components/domains/indemnity/IndemnityHistoryModal.vue'),

  // Insurance
  INSURANCE_SUB_PROGRAM_DEACTIVATE_MODAL: () => import('@/components/domains/insurance/InsuranceSubProgramDeactivateModal.vue'),
  INSURANCE_POLICY_EXPORT: () => import('@/components/domains/insurance/InsurancePolicyExport.vue'),

  // Laboratories
  GLUE_OUR_SERVICE_MODAL: () => import('@/components/domains/laboratories/GlueOurServiceModal.vue'),
  GLUE_PARTNER_SERVICE_MODAL: () => import('@/components/domains/laboratories/GluePartnerServiceModal.vue'),
  ADD_TAG_MODAL: () => import('@/components/domains/laboratories/AddTagModal.vue'),
  EDIT_TAG_MODAL: () => import('@/components/domains/laboratories/EditTagModal.vue'),

  // Legal Partners
  ADD_LEGAL_PARTNER_MODAL: () => import('@/components/domains/laboratories/legalPartners/AddLegalPartnerModal.vue'),
  EDIT_LEGAL_PARTNER_MODAL: () => import('@/components/domains/laboratories/legalPartners/EditLegalPartnerModal.vue'),

  // Patient
  PATIENT_CREATE: () => import('@/components/domains/patient/PatientCreateModal.vue'),
  PATIENT_CREATE_SELECT_MODE: () => import('@/components/domains/patient/PatientCreateSelectModeModal.vue'),
  PATIENT_SERVICES_MODAL: () => import('@/components/domains/patient/PatientServicesModal.vue'),
  PATIENT_TRANSFER_MODAL: () => import('@/components/domains/patient/PatientTransferModal.vue'),
  PATIENT_DETAIL_MODAL: () => import('@/components/domains/patient/PatientDetailModal.vue'),
  PATIENT_DETAIL_CHAT_MODAL: () => import('@/components/domains/patient/PatientDetailChatModal.vue'),
  PATIENT_DELETE_MODAL: () => import('@/components/domains/patient/PatientDeleteModal.vue'),
  PATIENT_DEPERSONALIZATE_MODAL: () => import('@/components/domains/patient/PatientDepersonalizateModal.vue'),
  PATIENT_DEVICE_MODAL: () => import('@/components/domains/patient/PatientDeviceModal.vue'),
  PATIENT_DEVICE_DELETE_MODAL: () => import('@/components/domains/patient/PatientDeviceDeleteModal.vue'),

  // !!! Monitoring, why path is not include folder domains?
  MONITORING_CREATE_CALL_MODAL: () => import('@/components/Patients/Modals/MonitoringCreateCallModal'),
  MONITORING_MESSAGES_HISTORY_MODAL: () => import('@/components/Patients/Modals/MonitoringMessagesHistoryModal'),
  MONITORING_POLICIES_MODAL: () => import('@/components/Patients/Modals/MonitoringPoliciesModal'),
  FINAL_STATUS_MODAL: () => import('@/components/Patients/Modals/FinalStatusModal'),
  REMOVE_MULTY_MONITORINGS_MODAL: () => import('@/components/Patients/Modals/RemoveMultyMonitoringsModal'),

  // Orders
  UPLOAD_ORDER_ANALYSIS_MODAL: () => import('@/components/domains/laboratories/orders/UploadOrderAnalysis.vue'),
  ORDERS_HISTORY_MODAL: () => import('@/components/domains/laboratories/orders/OrdersHistoryModal.vue'),

  // Partners
  ADD_PARTNER_MODAL: () => import('@/components/domains/laboratories/partners/AddPartnerModal.vue'),
  EDIT_PARTNER_MODAL: () => import('@/components/domains/laboratories/partners/EditPartnerModal.vue'),

  // PHONE
  CHECK_PHONE_NUMBER_MODAL: () => import('@/components/domains/phone/CheckPhoneNumberModal.vue'),

  // Policies
  COMBINE_POLICIES_MODAL: () => import('@/components/domains/policies/CombinePoliciesModal.vue'),
  POLICY_DETACH_MODAL: () => import('@/components/domains/policies/PolicyDetachModal.vue'),
  POLICY_DETACH_DOP_MODAL: () => import('@/components/domains/policies/PolicyDetachDopModal.vue'),
  POLICY_CREATE_MODAL: () => import('@/components/domains/policies/PolicyCreateModal.vue'),
  POLICY_VIEW_MODAL: () => import('@/components/domains/policies/PolicyViewModal.vue'),
  DELETE_POLICY_MODAL: () => import('@/components/domains/policies/DeletePolicyModal.vue'),

  // Promocodes
  PROMOCODE_EDIT_MODAL: () => import('@/components/domains/promocodes/PromocodeEditModal.vue'),

  // Protocol
  PROTOCOLS_MODAL: () => import('@/components/domains/consultations/ProtocolsModal.vue'),

  // Reestr
  LEGAL_ENTITY_REESTR_IMPORT_MODAL: () => import('@/components/domains/reestr/LegalEntityReestrImportModal.vue'),

  // Regions
  REGION_EDIT_MODAL: () => import('@/components/domains/regions/RegionEditModal.vue'),

  // Reservation
  RESERVATION_HISTORY_MODAL: () => import('@/components/domains/reservation/ReservationHistoryModal.vue'),
  RESERVATION_DELETE_MODAL: () => import('@/components/domains/reservation/ReservationDeleteModal.vue'),

  // Schedule
  SCHEDULE_CANCEL_MODAL: () => import('@/components/domains/schedule/ScheduleCancelModal.vue'),
  SCHEDULE_INFORMATION_MODAL: () => import('@/components/domains/schedule/ScheduleInformationModal.vue'),
  SCHEDULE_TEMPLATE_EDIT_MODAL: () => import('@/components/domains/schedule/ScheduleTemplateEditModal.vue'),

  // Services
  IMPORT_SERVICES_MODAL: () => import('@/components/domains/laboratories/services/ImportServicesModal.vue'),
  ADD_SERVICE_MODAL: () => import('@/components/domains/laboratories/services/AddServiceModal.vue'),
  EDIT_LABORATORY_SERVICE_MODAL: () => import('@/components/domains/laboratories/services/EditLaboratoryServiceModal.vue'),
  SERVICE_PRICES_MODAL: () => import('@/components/domains/laboratories/services/ServicePricesModal.vue'),
  IMPORT_PARTNER_SERVICE_MODAL: () => import('@/components/domains/laboratories/services/ImportPartnerService.vue'),
  PARTNER_SERVICE_MAP_MODAL: () => import('@/components/domains/laboratories/services/PartnerServiceMap.vue'),

  // Sms
  SMS_SEND_MODAL: () => import('@/components/domains/sms/SmsSendModal.vue'),
  SMS_TEMPLATES_MODAL: () => import('@/components/domains/sms/SmsTemplatesModal.vue'),
  SMS_TEMPLATE_EDIT_MODAL: () => import('@/components/domains/sms/SmsTemplateEditModal.vue'),
  SMS_TEMPLATE_DELETE_MODAL: () => import('@/components/domains/sms/SmsTemplateDeleteModal.vue'),

  // Specialization
  SPECIALIZATION_EDIT_MODAL: () => import('@/components/domains/specialization/SpecializationEditModal.vue'),
  SPECIALIZATION_DOCTORS_MODAL: () => import('@/components/domains/specialization/SpecializationDoctorsModal.vue'),
  SPECIALIZATION_DELETE_MODAL: () => import('@/components/domains/specialization/SpecializationDeleteModal.vue'),
  SPECIALIZATION_REESTABLISH_MODAL: () => import('@/components/domains/specialization/SpecializationReestablishModal.vue'),

  // Stories
  STORY_COVER_ADD_MODAL: () => import('@/components/domains/stories/StoryCoverAddModal.vue'),
  STORY_DELETE_MODAL: () => import('@/components/domains/stories/StoryDeleteModal.vue'),

  // Users
  USER_VIEW_MODAL: () => import('@/components/domains/users/UserViewModal.vue'),
  USER_DELETE_MODAL: () => import('@/components/domains/users/UserDeleteModal.vue'),
  USER_REMOVE_MODAL: () => import('@/components/domains/users/UserRemoveModal.vue'),

  INDEMNITY_PRICE_CLINIC_TEMPLATE_EDIT_MODAL: () => import('@/components/domains/indemnity/IndemnityPriceClinicTemplateEditModal.vue'),
  INDEMNITY_PRICE_CLINIC_TEMPLATE_DELETE_MODAL: () => import('@/components/domains/indemnity/IndemnityPriceClinicTemplateDeleteModal.vue'),

};
