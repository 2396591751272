<script>
import MomSchoolCreateEventDTO from '@/components/MomSchool/DTOs/MomSchoolCreateEventDTO';
import { momSchoolService } from '@/services';
import { addDays, format, parseISO } from '@evd3v/date-fns';
import MomSchoolEditEventDTO from '@/components/MomSchool/DTOs/MomSchoolEditEventDTO';
import Bus from '@/eventBus';
import { BaseDatePicker } from '@/components/base';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'EditEventModal',
  components: {
    BaseDatePicker,
  },
  props: {
    event: {
      type: Object,
      default: null,
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
  },
  emits: ['created', 'updated'],
  data() {
    return {
      isOpen: true,
      eventModel: {
        name: this.event?.name ?? '',
        eventDate: this.getFormattedDate(this.event?.startDate),
        eventTime: this.getFormattedTime(this.event?.startDate),
      },
      isLoading: false,
    };
  },
  validations: {
    eventModel: {
      name: {
        required,
      },
      eventDate: {
        required,
      },
      eventTime: {
        required,
        correctTime: (value) => {
          if (value.length !== 5) {
            return false;
          }
          const [hours, minutes] = value.split(':');
          const isHoursValid = Number(hours) >= 0 && Number(hours) <= 23;
          const isMinutesValid = Number(minutes) >= 0 && Number(minutes) <= 59;
          return isHoursValid && isMinutesValid;
        },
      },
    },
  },
  methods: {
    format,
    addDays,
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, {
        modalName: this.modalName,
      });
    },
    getFormattedDate(eventDateTime) {
      if (eventDateTime) {
        return new Date(format(parseISO(eventDateTime), 'yyyy-MM-dd'));
      }
      return null;
    },
    getFormattedTime(eventDateTime) {
      if (eventDateTime) {
        return format(parseISO(eventDateTime), 'HH:mm:ss');
      }
      return '';
    },
    async onsubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.isLoading = true;
          if (this.event && this.event.id) {
            const editEventDTO = new MomSchoolEditEventDTO(
              this.event.id,
              this.eventModel.name,
              this.eventModel.eventDate,
              this.eventModel.eventTime,
            );
            await momSchoolService.editEvent(editEventDTO);
            Bus.$emit('updated');
          } else {
            const createEventDTO = new MomSchoolCreateEventDTO(
              this.eventModel.name,
              this.eventModel.eventDate,
              this.eventModel.eventTime,
            );
            await momSchoolService.createEvent(createEventDTO);
            Bus.$emit('created');
          }
          this.isOpen = false;
        } catch (e) {
          console.warn(e);
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>

<template>
  <b-modal
    v-model="isOpen"
    :title="`${event?.id ? 'Редактирование' : 'Добавление'} вебинара`"
    centered
    hide-footer
    @hidden="onClose"
  >
    <b-form @submit.prevent="onsubmit">
      <b-form-group label="Название вебинара">
        <b-form-input
          v-model="$v.eventModel.name.$model"
          placeholder="Введите название вебинара"
          :state="$v.eventModel.name.$dirty ? !$v.eventModel.name.$error : null"
        />
        <b-form-invalid-feedback>
          Обязательное поле
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Дата вебинара">
        <base-date-picker
          v-model="$v.eventModel.eventDate.$model"
          :min-date="addDays(new Date(), 1)"
          :error="$v.eventModel.eventDate.$error"
          mask="##.##.####"
        />
        <b-form-invalid-feedback :state="!$v.eventModel.eventDate.$error">
          Обязательное поле
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Время вебинара">
        <b-form-input
          v-model="$v.eventModel.eventTime.$model"
          v-maska="'##:##'"
          placeholder="__:__"
          :state="
            $v.eventModel.eventTime.$dirty
              ? !$v.eventModel.eventTime.$error
              : null
          "
        />
        <b-form-invalid-feedback :state="!$v.eventModel.eventTime.$error">
          {{
            !$v.eventModel.eventTime.required
              ? 'Обязательное поле'
              : 'Введите корректное время'
          }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-button
        type="submit"
        variant="primary"
        :disabled="isLoading"
        class="ml-auto d-block"
      >
        <b-spinner
          v-if="isLoading"
          small
        />
        Сохранить
      </b-button>
    </b-form>
  </b-modal>
</template>
