import { z } from 'zod';

const dateSchema = z.object({
  year: z.number().nullable().refine((value) => value !== null && !Number.isNaN(value), 'Укажите корректный год'),
});
// eslint-disable-next-line import/prefer-default-export
export const legalEntityReestrExportModalSchema = z.object({
  registerInfo: z.object({
    dates: z.array(dateSchema).nonempty('Добавьте хотя бы одну дату'),
  }),
});
