import { APP_HOST } from '@/app.config';

export default class MomSchoolApi {
  constructor($axios) {
    this.api = $axios.instance;
  }

  async getEvents(params) {
    const { data } = await this.api.get(`${APP_HOST}v3/moms-school-event/list`, { params });
    return data;
  }

  async createEvent(params) {
    const { data } = await this.api.post(`${APP_HOST}v3/moms-school-event/create`, params);
    return data;
  }

  async editEvent(params) {
    const { data } = await this.api.post(`${APP_HOST}v3/moms-school-event/edit`, params);
    return data;
  }

  async deleteEvent(params) {
    const { data } = await this.api.post(`${APP_HOST}v3/moms-school-event/remove`, params);
    return data;
  }
}
