<template>
  <div class="consultations-info">
    <div
      v-if="!totalCount && isAllItemsLoaded"
      class="no-consultations"
    >
      <span>Нет консультаций</span>
    </div>
    <template
      v-else
    >
      <async-list
        :is-all-items-loaded="isAllItemsLoaded"
        :disable-toggle-header="true"
        class="consultations-list"
        @load-more-items="loadMoreItems"
      >
        <div
          v-for="consultation in queueConsultations"
          :key="consultation.id"
          class="pl-2"
        >
          <PatientDetailQueueConsultationsItem
            :consultation="consultation"
            :class="{ active: activeConsultation && consultation.id === activeConsultation.id }"
            @click.native="setActiveConsultation(consultation, 'queue')"
          />
        </div>

        <div
          v-for="consultation in consultations"
          :key="consultation.id"
          class="pl-2"
        >
          <PatientDetailConsultationsItem
            :consultation="consultation"
            :class="{ active: activeConsultation && consultation.id === activeConsultation.id }"
            @click.native="setActiveConsultation(consultation, 'consultation')"
          />
        </div>
      </async-list>

      <PatientDetailConsultation
        v-if="activeConsultation && activeConsultation.consultationType === 'consultation'"
        :consultation="activeConsultation"
      />

      <PatientDetailQueueConsultation
        v-if="activeConsultation && activeConsultation.consultationType === 'queue'"
        :consultation="activeConsultation"
      />

      <div
        v-if="!activeConsultation"
        class="no-active-consultation"
      >
        Выберите консультацию из списка слева, чтобы посмотреть полную информацию о ней
      </div>
    </template>
  </div>
</template>

<script>
import Bus from '@/eventBus';

import AsyncList from '@/components/AsyncList';
import PatientDetailConsultationsItem from '@/components/Patients/PatientDetailConsultationsItem';
import PatientDetailConsultation from '@/components/Patients/PatientDetailConsultation';
import PatientDetailQueueConsultationsItem from '@/components/Patients/PatientDetailQueueConsultationsItem';
import PatientDetailQueueConsultation from '@/components/Patients/PatientDetailQueueConsultation';

const LIMIT = 15;

export default {
  name: 'PatientDetailConsultations',
  components: {
    AsyncList,
    PatientDetailConsultationsItem,
    PatientDetailConsultation,
    PatientDetailQueueConsultationsItem,
    PatientDetailQueueConsultation,
  },
  props: {
    patientId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      limit: LIMIT,
      queueConsultations: [],
    };
  },
  computed: {
    activeConsultation: {
      get() {
        return this.$store.state.Patients.detailConsultations.activeConsultation;
      },
      set(newVal) {
        this.$store.commit(this.$types.PATIENT_DETAIL_ACTIVE_CONSULATION_SET, newVal);
      },
    },
    isAllItemsLoaded: {
      get() {
        return this.$store.state.Patients.detailConsultations.isAllItemsLoaded;
      },
      set(newVal) {
        this.$store.commit(this.$types.PATIENT_DETAIL_CONSULATIONS_IS_ALL_ITEMS_LOADED_SET, newVal);
      },
    },
    isLoading: {
      get() {
        return this.$store.state.Patients.detailConsultations.isLoading;
      },
      set(newVal) {
        this.$store.commit(this.$types.PATIENT_DETAIL_CONSULATIONS_IS_LOADING_SET, newVal);
      },
    },
    consultations: {
      get() {
        return this.$store.state.Patients.detailConsultations.consultations;
      },
      set(newVal) {
        this.$store.commit(this.$types.PATIENT_CONSULTATIONS_SET, newVal);
      },
    },
    totalCount() {
      return this.consultations.length + this.queueConsultations.length;
    },
  },
  created() {
    this.consultationsFetch(true);

    Bus.$on('consultations:refresh', this.consultationsFetch);
    Bus.$on('consultations:update', this.handleConsultationUpdate);
  },
  beforeDestroy() {
    Bus.$off('consultations:refresh', this.consultationsFetch);
    Bus.$off('consultations:update', this.handleConsultationUpdate);

    this.consultations = [];
    this.isAllItemsLoaded = false;
    this.activeConsultation = null;
  },
  methods: {
    handleConsultationUpdate() {
      this.consultationsFetch(true);
    },
    async consultationsFetch(clear) {
      if (clear) {
        this.setActiveConsultation(null);
        this.consultations = [];
        this.queueConsultations = [];
      }

      this.isAllItemsLoaded = false;
      this.isLoading = true;

      const skip = clear ? 0 : this.totalCount;

      try {
        this.queueConsultations = await this.$store.dispatch(this.$types.QUEUES_CONSULTATIONS_BY_PATIENT_FETCH, this.patientId);
        await this.$store.dispatch(this.$types.PATIENT_CONSULTATIONS_FETCH, {
          skip,
          take: this.limit,
          patientId: this.patientId,
        });

        if (clear) {
          if (this.queueConsultations.length) {
            this.setActiveConsultation(this.queueConsultations[0], 'queue');
          } else if (this.consultations.length) {
            this.setActiveConsultation(this.consultations[0], 'consultation');
          }
        }

        if (skip + this.limit > this.totalCount) {
          this.isAllItemsLoaded = true;
        }
      } catch (e) {
        console.warn(e);
      } finally {
        this.isLoading = false;
      }
    },
    loadMoreItems() {
      if (!this.isLoading) {
        this.consultationsFetch();
      }
    },
    setActiveConsultation(consultation, type) {
      if (this.activeConsultation && this.activeConsultation.id === consultation.id) {
        this.activeConsultation = null;
        return;
      }

      this.activeConsultation = { ...consultation, consultationType: type };
    },
  },
};
</script>

<style lang="scss" scoped>

.consultations-info {
  display: flex;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #E0E0E0;
  overflow: auto;
  box-sizing: border-box;
  height: 100%;
}

.consultations-list {
  height: 100%;
  overflow: auto;
  width: 280px;
  border-right: 1px solid #E0E0E0;
  box-sizing: border-box;
  flex-shrink: 0;
}

.no-consultations {
  padding: 30px 15px 30px 30px;
  font-size: 16px;
  line-height: 20px;
  color: #908F8D;
}

.no-active-consultation {
  box-sizing: border-box;
  padding: 20px;
  flex: 1;
  display: flex;
  height: 100%;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
}
</style>
