import { z } from 'zod';

const minYear = 1900;
const maxYear = new Date().getFullYear();

// eslint-disable-next-line import/prefer-default-export
export const patientDetailPersonValidationSchema = z.object({
  patient: z.object({
    lastName: z.string().min(1, 'Поле не может быть пустым'),
    firstName: z.string().min(1, 'Поле не может быть пустым'),
    birthDate: z.date()
      .nullable()
      .refine((date) => date !== null, 'Поле не должно быть пустым')
      .refine((date) => {
        if (date === null) return false;
        const year = date.getFullYear();
        return year >= minYear && year <= maxYear;
      }, 'Укажите корректный год'),
    sex: z.boolean().nullable().refine((value) => value !== null, {
      message: 'Поле не может быть пустым',
    }),
    userName: z.string()
      .nullable()
      .refine((value) => {
        if (!value || value.trim().length === 0) {
          return true;
        }
        return /^\+7\d{10}$/.test(value);
      }, {
        message: 'Некорректное значение поля',
      }),
  }),
});
