import { z } from 'zod';

// eslint-disable-next-line import/prefer-default-export
export const queueEditModalSchema = z.object({
  queueData: z.object({
    name: z.string().nullable().refine((value) => value !== null && value !== '', 'Данное поле обязательно*'),
    description: z.string().nullable().refine((value) => value !== null && value !== '', 'Данное поле обязательно*'),
    tags: z.array(z.unknown()).nonempty({ message: 'Необходимо добавить тэг' }),
    doctors: z.array(z.unknown()).nonempty({ message: 'Необходимо добавить врача' }),
    consultationLimitForPatientPerDay: z
      .union([
        z.number().nullable().refine((value) => value > 0 && value !== null, {
          message: 'Значение должно быть больше 0',
        }),
        z.literal(''),
      ])
      .refine((value) => value !== '', {
        message: 'Некорректное значение поля',
      }),
    consultationLimitForPatientPerMonth: z
      .union([
        z.number().nullable().refine((value) => value > 0 && value !== null, {
          message: 'Значение должно быть больше 0',
        }),
        z.literal(''),
      ])
      .refine((value) => value !== '', {
        message: 'Некорректное значение поля',
      }),
    consultationLimitForDoctor: z
      .union([
        z.number().nullable().refine((value) => value > 0 && value !== null, {
          message: 'Значение должно быть больше 0',
        }),
        z.literal(''),
      ])
      .refine((value) => value !== '', {
        message: 'Некорректное значение поля',
      }),
  }),
  clinic: z.boolean().refine((val) => val === true, {
    message: 'Необходимо выбрать клинику',
  }),
  patientPerDayLimit: z.boolean().refine((val) => val === true, {
    message: 'Значение не может быть больше лимита консультаций на месяц',
  }),
  patientPerMonthLimit: z.boolean().refine((val) => val === true, {
    message: 'Значение не может быть меньше лимита консультаций в день',
  }),
});
