import { APP_HOST } from '@/app.config';

export default class RemoteMonitoringApi {
  constructor($axios) {
    this.api = $axios.instance;
  }

  async getMonitoringTableData(params) {
    const { data, headers } = await this.api.get(`${APP_HOST}v2/crm-monitoring/list`,
      {
        params,
      });
    return { data, headers };
  }

  async smsList(payload) {
    const { data, headers } = await this.api.get(`${APP_HOST}Sms/List?skip=${payload.skip}&take=${payload.take}
    &phoneNumber=${payload.phoneNumber}`);
    return {
      data,
      totalCount: headers['x-total-count'] || headers.get('x-total-count'),
    };
  }

  async getRemoteMonitoringTemplatesList() {
    const { data } = await this.api.get(`${APP_HOST}v2/monitoring/templates/list/short`);
    return data;
  }

  async fetchSpecialization(search) {
    const { data } = await this.api.get(`${APP_HOST}v2/specializations/find`,
      {
        params: {
          search,
        },
      });
    return data;
  }

  async removePatientMonitorings(ids) {
    const { data } = await this.api.post(`${APP_HOST}v2/monitoring/delete`,
      {
        monitoringIds: ids,
      });
    return data;
  }
}
