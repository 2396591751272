import { APP_HOST } from '@/app.config';

export default class VtbFormApi {
  constructor($axios) {
    this.api = $axios.instance;
  }

  async setFinalStatus(params) {
    const { data } = await this.api.post(`${APP_HOST}v2/vtb-form/final-status`, params);
    return data;
  }

  async setQuestionnaire(params) {
    const { data } = await this.api.post(`${APP_HOST}v2/vtb-form/questionnaire`, params);
    return data;
  }
}
