import { z } from 'zod';

// eslint-disable-next-line import/prefer-default-export
export const scheduleMakeAnAppointmentModalFirstSchema = z.object({
  checkup: z.object({
    patientId: z.number().nullable().refine((value) => value !== null && !Number.isNaN(value), {
      message: 'Выберите существующего пользователя или создайте нового',
    }),
    communicationMethod: z.string().nullable().refine((value) => value !== null && value !== '',
      'Выберите способ связи'),
    availabilityOfPayment: z.boolean().nullable().refine((value) => value !== null, {
      message: 'Телефон не подтвержден',
    }),
    body: z.string().min(1, 'Данное поле обязательно*'),
  }),
});

// eslint-disable-next-line import/prefer-default-export
export const scheduleMakeAnAppointmentModalSecondSchema = z.object({
  checkup: z.object({
    lastName: z.string().min(1, 'Поле не может быть пустым'),
    firstName: z.string().min(1, 'Поле не может быть пустым'),
    birthDate: z.date()
      .or(z.literal(null))
      .refine((date) => date !== null, 'Поле не может быть пустым'),
    sex: z.boolean().nullable().refine((value) => value !== null, {
      message: 'Поле не может быть пустым',
    }),
    phoneNumber: z.string().regex(/^\+\d \(\d{3}\) \d{3} \d{2}-\d{2}$/, 'Некорректное значение поля'),
    communicationMethod: z.string().nullable().refine((value) => value !== null && value !== '',
      'Выберите способ связи'),
    availabilityOfPayment: z.boolean().nullable().refine((value) => value !== null, {
      message: 'Телефон не подтвержден',
    }),
    body: z.string().min(1, 'Данное поле обязательно*'),

  }),
});
