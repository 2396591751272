import NProgress from 'nprogress/nprogress';

import * as types from '@/store/types';
import { removeNamespaces, saveFile, showMessage } from '@/helpers/utils';
import { api } from '@/helpers/api';
import axios from 'axios';

const localTypes = removeNamespaces(types);

// function mockApi(object, time) {
//   return new Promise((resolve) => {
//     setTimeout(() => resolve(object), time);
//   });
// }

export default {
  async [localTypes.GET_LABORATORY_PARTNERS](
    { commit },
    {
      isActive,
      offSet,
      limit,
    },
  ) {
    NProgress.start();

    try {
      const params = new URLSearchParams();

      if (isActive !== undefined) params.append('isActive', isActive);
      if (offSet !== undefined) params.append('offSet', offSet);
      if (limit) params.append('limit', limit);

      const {
        data,
        headers,
      } = await api.get('v3/laboratory/partner/list', {
        params,
      });

      NProgress.done();

      commit(localTypes.PARTNERS_SET, data);

      return {
        data,
        xTotalCount: headers['x-total-count'] || headers.get('x-total-count'),
      };
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LABORATORY_ORDER_HISTORY_FETCH](_, params) {
    NProgress.start();

    try {
      // Такого запроса пока нет! Щас стоит пример
      const { data } = await api.get(`v3/laboratory/order/history?orderId=${params}`);
      NProgress.done();
      return data;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.GET_LABORATORY_TAGS](
    { commit },
    {
      isActive,
      offSet,
      limit,
      TagType,
      Name,
    },
  ) {
    NProgress.start();

    try {
      const params = new URLSearchParams();

      if (isActive !== undefined) params.append('isActive', isActive);
      if (offSet !== undefined) params.append('offSet', offSet);
      if (limit) params.append('limit', limit);
      if (TagType) params.append('TagType', TagType);
      if (Name) params.append('Name', Name);

      const {
        data,
        headers,
      } = await api.get('v3/laboratory/tag/list', {
        params,
      });

      NProgress.done();

      commit(localTypes.PARTNERS_SET, data);

      return {
        data,
        xTotalCount: headers['x-total-count'] || headers.get('x-total-count'),
      };
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.GET_LABORATORY_PARTNER_FOR_ID](_, partnerId) {
    NProgress.start();

    try {
      const { data } = await api.get(
        `v3/laboratory/partner/get?id=${partnerId}`,
      );

      NProgress.done();

      return data;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.GET_LABORATORY_TAG_FOR_ID](_, tagId) {
    NProgress.start();

    try {
      const { data } = await api.get(`v3/laboratory/tag/get?id=${tagId}`);

      NProgress.done();

      return data;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.GET_LABORATORY_CITY_FOR_ID](_, cityId) {
    NProgress.start();

    try {
      const { data } = await api.get(`/Cities/${cityId}`);

      NProgress.done();

      return data;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.GET_LABORATORY_SERVICE_FOR_ID](_, serviceId) {
    NProgress.start();

    try {
      const { data } = await api.get(
        `v3/laboratory/service/get?id=${serviceId}`,
      );

      NProgress.done();

      return data;
    } catch (e) {
      console.error(e);
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось получить данные о лаборатории',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.GET_LABORATORY_LEGAL_PARTNER_FOR_ID](_, legalPartnerId) {
    NProgress.start();

    try {
      const { data } = await api.get(
        `v3/laboratory/legal-partner/get?id=${legalPartnerId}`,
      );

      NProgress.done();

      return data;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.GET_LABORATORY_LEGAL_PARTNERS](
    { commit },
    {
      isActive,
      offSet,
      limit,
      LegalName,
      Inn,
      PartnerId,
    },
  ) {
    NProgress.start();
    commit(localTypes.PARTNERS_IS_LOADING, { isLoading: true });

    try {
      const params = new URLSearchParams();

      if (isActive !== undefined) params.append('isActive', isActive);
      if (offSet !== undefined) params.append('offSet', offSet);
      if (limit) params.append('limit', limit);
      if (LegalName) params.append('LegalName', LegalName);
      if (Inn) params.append('Inn', Inn);
      if (PartnerId) params.append('PartnerId', PartnerId);

      const {
        data,
        headers,
      } = await api.get('v3/laboratory/legal-partner/list', { params });
      const xTotalCount = headers['x-total-count'] || headers.get('x-total-count');

      commit(localTypes.LEGAL_PARTNERS_SET, data);

      NProgress.done();

      return {
        data,
        xTotalCount,
      };
    } finally {
      NProgress.done();
      commit(localTypes.PARTNERS_IS_LOADING, { isLoading: false });
    }
  },
  async [localTypes.GET_LABORATORY_BRANCHES](
    { commit },
    {
      isActive,
      offSet,
      limit,
      Name,
      CityId,
      Address,
      LegalPartnerId,
      Longitude,
      Latitude,
      CityName,
    },
  ) {
    NProgress.start();

    try {
      const params = new URLSearchParams();

      if (isActive !== undefined) params.append('isActive', isActive);
      if (offSet !== undefined) params.append('offSet', offSet);
      if (limit) params.append('limit', limit);
      if (Name) params.append('Name', Name);
      if (CityId) params.append('CityId', CityId);
      if (Address) params.append('Address', Address);
      if (LegalPartnerId) params.append('LegalPartnerId', LegalPartnerId);
      if (Longitude) params.append('Longitude', Longitude);
      if (Latitude) params.append('Latitude', Latitude);
      if (CityName) params.append('CityName', CityName);

      const {
        data,
        headers,
      } = await api.get('v3/laboratory/branch/list', {
        params,
      });
      const xTotalCount = headers['x-total-count'] || headers.get('x-total-count');

      commit(localTypes.BRANCHES_SET, data);

      NProgress.done();

      return {
        data,
        xTotalCount,
      };
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.GET_LABORATORY_PARTNER_SERVICES](
    { commit },
    {
      isActive,
      offSet,
      limit,
      MinCost,
      MaxCost,
      Code,
      Name,
      LegalPartnerId,
      save,
      isMerged,
      isMappable,
    },
  ) {
    NProgress.start();

    try {
      const params = new URLSearchParams();

      if (isActive !== undefined) params.append('isActive', isActive);
      if (isMerged !== undefined && isMerged !== null) params.append('isMerged', isMerged);
      if (isMappable !== undefined && isMappable !== null) params.append('MapableOnly', isMappable);
      if (offSet !== undefined) params.append('offSet', offSet);
      if (limit) params.append('limit', limit);
      if (MinCost) params.append('MinCost', MinCost);
      if (MaxCost) params.append('MaxCost', MaxCost);
      if (Code) params.append('Code', Code);
      if (Name) params.append('Name', Name);
      if (LegalPartnerId) params.append('LegalPartnerId', LegalPartnerId);

      const {
        data,
        headers,
      } = await api.get('v3/laboratory/partner-service/list', { params });
      const xTotalCount = headers['x-total-count'] || headers.get('x-total-count');

      NProgress.done();
      if (save) commit(localTypes.PARTNER_SERVICES_SET, data);

      return {
        data,
        xTotalCount,
      };
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.GET_LABORATORY_ORDERS](
    { commit },
    {
      isActive,
      offSet,
      limit,
      Status,
      save,
    },
  ) {
    NProgress.start();

    try {
      const params = new URLSearchParams();

      if (isActive !== undefined) params.append('isActive', isActive);
      if (offSet !== undefined) params.append('offSet', offSet);
      if (limit) params.append('limit', limit);
      if (Status !== undefined && Status !== null) params.append('status', Status);

      const {
        data,
        headers,
      } = await api.get('v3/laboratory/order/list', {
        params,
      });
      const xTotalCount = headers['x-total-count'] || headers.get('x-total-count');

      NProgress.done();
      if (save) commit(localTypes.ORDER_ITEMS_SET, data);

      return {
        data,
        xTotalCount,
      };
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LABORATORY_ORDER_CANCEL](_, orderId) {
    NProgress.start();
    try {
      const { data } = await api.post(
        `v3/laboratory/order/cancel?id=${orderId}`,
      );
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Заказ отменен!',
      });
      NProgress.done();
      return data;
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось отменить заказ',
      });

      throw new Error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LABORATORY_ORDER_COMPLETE](_, orderId) {
    NProgress.start();
    try {
      const { data } = await api.post(
        `v3/laboratory/order/complete?id=${orderId}`,
      );
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Заказ завершен!',
      });
      NProgress.done();
      return data;
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось завершить заказ',
      });

      throw new Error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.GET_LABORATORY_PARTNER_SERVICES_FOR_ID](
    _,
    partnerServiceId,
  ) {
    NProgress.start();

    try {
      const { data } = await api.get(
        `v3/laboratory/partner-service/get?id=${partnerServiceId}`,
      );

      NProgress.done();

      return data;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.GET_METRO_FOR_CITY](
    _,
    cityId,
  ) {
    NProgress.start();

    try {
      const data = axios.get(`https://api.hh.ru/metro/${cityId}`);

      NProgress.done();

      return data;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.GET_LABORATORY_ORDER_FOR_ID]({ commit }, params) {
    NProgress.start();

    try {
      const { data } = await api.get(
        `v3/laboratory/order/get?id=${params.orderId}`,
      );

      NProgress.done();
      if (params.save) commit(localTypes.CURRENT_ORDER_SET, data);

      return data;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.GET_LABORATORY_SERVICES](
    { commit },
    {
      isActive,
      isMerged,
      isCheckup,
      offSet,
      limit,
      MinCost,
      MaxCost,
      Code,
      Name,
      ParentId,
      LegalPartnerId,
      DoesNotMergedLegalPartnerIds,
    },
  ) {
    NProgress.start();
    commit(localTypes.PARTNERS_IS_LOADING, { isLoading: true });

    try {
      const params = new URLSearchParams();

      if (isActive !== undefined) params.append('isActive', isActive);
      if (isMerged !== undefined) params.append('isMerged', isMerged);
      if (isCheckup !== undefined) params.append('isCheckup', isCheckup);
      if (offSet !== undefined) params.append('offSet', offSet);
      if (limit) params.append('limit', limit);
      if (MinCost) params.append('MinCost', MinCost);
      if (MaxCost) params.append('MaxCost', MaxCost);
      if (Code) params.append('Code', Code);
      if (Name) params.append('Name', Name);
      if (ParentId) params.append('ParentId', ParentId);
      if (LegalPartnerId) params.append('LegalPartnerId', LegalPartnerId);
      if (Array.isArray(DoesNotMergedLegalPartnerIds) && DoesNotMergedLegalPartnerIds.length) {
        DoesNotMergedLegalPartnerIds.forEach((item) => {
          params.append('DoesNotMergedLegalPartnerIds', item);
        });
      }

      const {
        data,
        headers,
      } = await api.get('v3/laboratory/service/list', {
        params,
      });
      const xTotalCount = headers['x-total-count'] || headers.get('x-total-count');

      NProgress.done();

      commit(localTypes.SERVICES_SET, data);

      return {
        data,
        xTotalCount,
      };
    } finally {
      NProgress.done();
      commit(localTypes.PARTNERS_IS_LOADING, { isLoading: false });
    }
  },
  async [localTypes.LABORATORY_PARTNER_ACTIVATE](_, partnerId) {
    NProgress.start();
    try {
      const { data } = await api.post(
        `v3/laboratory/partner/activate?id=${partnerId}`,
      );
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Партнер активирован!',
      });
      NProgress.done();
      return data;
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось активировать партнера',
      });

      throw new Error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LABORATORY_TAG_ACTIVATE](_, tagId) {
    NProgress.start();
    try {
      const { data } = await api.post(`v3/laboratory/tag/activate?id=${tagId}`);
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Категория активирована!',
      });
      NProgress.done();
      return data;
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось активировать категорию',
      });

      throw new Error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LABORATORY_LEGAL_PARTNER_ACTIVATE](_, legalPartnerId) {
    NProgress.start();
    try {
      const { data } = await api.post(
        `v3/laboratory/legal-partner/activate?id=${legalPartnerId}`,
      );
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Юр.лицо активировано!',
      });
      NProgress.done();
      return data;
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось активировать Юр.лицо',
      });

      throw new Error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LABORATORY_LEGAL_PARTNER_DEACTIVATE](_, legalPartnerId) {
    NProgress.start();
    try {
      const { data } = await api.post(
        `v3/laboratory/legal-partner/deactivate?id=${legalPartnerId}`,
      );
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Юр.лицо деактивировано!',
      });
      NProgress.done();
      return data;
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось деактивировать Юр.лицо',
      });

      throw new Error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LABORATORY_TAG_DEACTIVATE](_, tagId) {
    NProgress.start();
    try {
      const { data } = await api.post(
        `v3/laboratory/tag/deactivate?id=${tagId}`,
      );
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Категория деактивирована!',
      });
      NProgress.done();
      return data;
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось деактивировать категорию',
      });

      throw new Error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LABORATORY_PARTNER_DEACTIVATE](_, partnerId) {
    NProgress.start();
    try {
      const { data } = await api.post(
        `v3/laboratory/partner/deactivate?id=${partnerId}`,
      );
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Партнер деактивирован!',
      });
      NProgress.done();
      return data;
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось деактивировать партнера',
      });

      throw new Error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LABORATORY_PARTNER_CREATE](_, fd) {
    NProgress.start();

    try {
      await api.post('v3/laboratory/partner/create', fd, {
        'Content-Type': 'multipart/form-data',
      });
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Партнер создан!',
      });
      NProgress.done();
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось создать партнера',
      });

      throw new Error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LABORATORY_TAG_CREATE](_, params) {
    NProgress.start();
    try {
      await api.post('v3/laboratory/tag/create', params);
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Категория создана!',
      });
      NProgress.done();
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось создать категорию',
      });

      throw new Error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LABORATORY_LEGAL_PARTNER_CREATE](_, fd) {
    NProgress.start();
    try {
      await api.post('v3/laboratory/legal-partner/create', fd,
        {
          'Content-Type': 'multipart/form-data',
        });
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Юр.лицо создано!',
      });
      NProgress.done();
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось создать Юр.лицо',
      });

      throw new Error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LABORATORY_PARTNER_UPDATE](_, params) {
    NProgress.start();
    let param = {};
    param = {
      name: params.name,
      isActive: params.isActive,
      contactPhoneNumber: params.contactPhoneNumber,
    };
    try {
      await api.post(`v3/laboratory/partner/update?id=${params.id}`, param);
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Партнер изменен!',
      });
      NProgress.done();
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось изменить партнера',
      });

      throw new Error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LABORATORY_PARTNER_UPLOAD_LOGO](_, params) {
    NProgress.start();
    const fd = new FormData();
    fd.set('logo', params.logo);

    try {
      await api.post(`v3/laboratory/partner/upload-logo?id=${params.id}`, fd,
        {
          'Content-Type': 'multipart/form-data',
        });
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Партнер изменен!',
      });
      NProgress.done();
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось изменить партнера',
      });

      throw new Error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LABORATORY_PARTNER_DELETE_LOGO](_, params) {
    NProgress.start();

    try {
      await api.delete(`v3/laboratory/partner/delete-logo?id=${params.id}`);
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Логотип удален!',
      });
      NProgress.done();
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось удалить логотип',
      });

      throw new Error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LABORATORY_TAG_UPDATE](_, params) {
    NProgress.start();
    let param = {};
    param = {
      name: params.name,
      tagType: params.tagType,
    };
    try {
      await api.post(`v3/laboratory/tag/update?id=${params.id}`, param);
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Партнер изменен!',
      });
      NProgress.done();
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось изменить партнера',
      });

      throw new Error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.GET_LABORATORY_ORDER_DOWNLOAD_ANALYSIS](_, params) {
    NProgress.start();

    try {
      const { data } = await api.get(
        `v3/laboratory/order/download-analysis?analysisId=${params.analysisId}`,
        {
          responseType: 'blob',
        },
      );

      NProgress.done();

      saveFile(data, { name: params.fileName });
    } catch (e) {
      console.warn(e);
      showMessage({
        type: 'error',
        message: 'Не удалось выгрузить анализ',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.GET_LABORATORY_PARTNER_PRICES](_, params) {
    NProgress.start();

    try {
      const { data } = await api.get(
        (`/v3/laboratory/import/partner-services/import-result?fileKey=${params.fileKey}`),
        {
          responseType: 'blob',
        },
      );
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Файл успешно выгружен!',
      });

      NProgress.done();

      saveFile(data, { name: params.fileName });
    } catch (e) {
      console.warn(e);
      showMessage({
        type: 'error',
        message: 'Не удалось выгрузить файл',
      });
    } finally {
      NProgress.done();
    }
  },

  async [localTypes.GET_LABORATORY_DOWNLOAD_BRANCHES](_, params) {
    NProgress.start();

    try {
      const { data } = await api.get(
        (`/v3/laboratory/import/branches/import-result?fileKey=${params.fileKey}`),
        {
          responseType: 'blob',
        },
      );
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Файл успешно выгружен!',
      });

      NProgress.done();

      saveFile(data, { name: params.fileName });
    } catch (e) {
      console.warn(e);
      showMessage({
        type: 'error',
        message: 'Не удалось выгрузить файл',
      });
    } finally {
      NProgress.done();
    }
  },

  async [localTypes.LABORATORY_DOWNLOAD_PARTNER_PRICES](_, params) {
    NProgress.start();
    try {
      const { data } = await api.post('/v3/laboratory/import/partner-services', params);

      return data;
    } catch (e) {
      console.warn(e);
      showMessage({
        type: 'error',
        message: 'Не удалось выгрузить прайс',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LABORATORY_IMPORT_BRANCHES](_, params) {
    NProgress.start();
    try {
      const { data } = await api.post('/v3/laboratory/import/branches', params);

      return data;
    } catch (e) {
      console.warn(e);
      showMessage({
        type: 'error',
        message: 'Не удалось выгрузить прайс',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LABORATORY_DOWNLOAD_GUARANTEE](_, params) {
    NProgress.start();
    const pd = {};
    try {
      const { data } = await api.post(
        `v3/laboratory/order/download-guarantee?guaranteeLetterId=${params.id}`, pd,
        {
          responseType: 'blob',
        },
      );

      NProgress.done();

      saveFile(data, { name: params.originalName });
    } catch (e) {
      console.warn(e);
      showMessage({
        type: 'error',
        message: 'Не удалось выгрузить гарантийное письмо',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LABORATORY_ORDER_STATUS_UPDATE](_, params) {
    NProgress.start();
    try {
      const data = await api.post(
        `v3/laboratory/order/update-status?id=${params.id}&orderStatus=${params.status}`,
      );
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Статус заказа изменен!',
      });
      NProgress.done();
      return data;
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось изменить статус заказа',
      });

      throw new Error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LABORATORY_LEGAL_PARTNER_UPDATE](_, params) {
    NProgress.start();

    try {
      await api.post(`v3/laboratory/legal-partner/update?id=${params.id}`, params.fd,
        {
          'Content-Type': 'multipart/form-data',
        });
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Юр.лицо изменено!',
      });
      NProgress.done();
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось изменить Юр.лицо',
      });

      throw new Error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LABORATORY_PARTNER_SERVICE_IMPORT](_, params) {
    NProgress.start();
    try {
      const fd = new FormData();

      if (params.importFile) {
        fd.append('file', params.importFile);
      }

      await api.post(
        `v3/laboratory/partner-service/import?legalPartnerGuid=${params.legalPartnerGuid}`,
        fd,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Услуги импортировались!',
      });
      NProgress.done();
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось импортировать услуги',
      });

      throw new Error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LABORATORY_SERVICE_IMPORT](_, params) {
    NProgress.start();
    try {
      const fd = new FormData();

      if (params.importFile) {
        fd.append('file', params.importFile);
      }

      await api.post(
        `v3/laboratory/service/import?serviceSource=${params.serviceSource}`,
        fd,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Услуги импортировались!',
      });
      NProgress.done();
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось импортировать услуги',
      });

      throw new Error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LABORATORY_ORDER_UPLOAD_RESULT_FILE](_, {
    orderId,
    files,
  }) {
    NProgress.start();
    try {
      const fd = new FormData();

      files.forEach((file) => fd.append('files', file));

      await api.post(
        'v3/laboratory/order/upload-result-files',
        fd,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
          params: { orderId },
        },
      );

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Файл загружен!',
      });
      NProgress.done();
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось загрузить файл',
      });

      throw new Error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LABORATORY_ORDER_DELETE_RESULT_FILE](_, fileId) {
    NProgress.start();
    try {
      const response = await api.delete('v3/laboratory/order/delete-result-file', { params: { fileId } });
      console.log(response);
      return response;
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Ошибка при удалении файла',
      });

      throw new Error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LABORATORY_ORDER_DOWNLOAD_RESULT_FILE](_, {
    fileId,
    name,
  }) {
    NProgress.start();
    try {
      const { data } = await api.get('v3/laboratory/order/download-result-file', {
        params: { fileId },
        responseType: 'blob',
      });

      saveFile(data, { name });
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Ошибка при скачивании файла',
      });

      throw new Error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LABORATORY_ORDER_UPLOAD_ANALYSIS](_, params) {
    NProgress.start();
    try {
      const fd = new FormData();

      if (params.importFile) {
        fd.append('file', params.importFile);
      }

      await api.post(
        `v3/laboratory/order/upload-analysis?orderItemId=${params.orderItemId}`,
        fd,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Анализ загружен!',
      });
      NProgress.done();
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось загрузить анализ',
      });

      throw new Error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LABORATORY_PARTNER_SERVICE_ACTIVATE](_, partnerServiceId) {
    NProgress.start();
    try {
      const { data } = await api.post(
        `v3/laboratory/partner-service/activate?id=${partnerServiceId}`,
      );
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Партнерская услуга активирована!',
      });
      NProgress.done();
      return data;
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось активировать партнерскую услугу',
      });

      throw new Error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LABORATORY_PARTNER_SERVICE_DEACTIVATE](
    _,
    partnerServiceId,
  ) {
    NProgress.start();
    try {
      const { data } = await api.post(
        `v3/laboratory/partner-service/deactivate?id=${partnerServiceId}`,
      );
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Партнерская услуга деактивирована!',
      });
      NProgress.done();
      return data;
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось деактивировать партнерскую услугу',
      });

      throw new Error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LABORATORY_PARTNER_SERVICE_SET_MAPPABLE](
    _,
    {
      partnerServiceId,
      value,
    },
  ) {
    NProgress.start();
    try {
      const { data } = await api.post(
        `v3/laboratory/partner-service/set-mapable?partnerServiceId=${partnerServiceId}&value=${value}`,
      );

      NProgress.done();
      return data;
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось изменить партнерскую услугу',
      });

      throw new Error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LABORATORY_PARTNER_SERVICE_MAP](_, params) {
    NProgress.start();
    try {
      await api.post(
        `v3/laboratory/partner-service/map?partnerServiceGuid=${params.partnerServiceGuid}&serviceGuid=${params.serviceGuid}`,
      );
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Услуги привязаны!',
      });
      NProgress.done();
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось привязать услуги',
      });

      throw new Error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LABORATORY_PARTNER_SERVICE_UNMAP](_, partnerServiceGuid) {
    NProgress.start();
    try {
      await api.post(
        `v3/laboratory/partner-service/unmap?partnerServiceGuid=${partnerServiceGuid}`,
      );
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Услуги отвязаны!',
      });
      NProgress.done();
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось отвязать услуги',
      });

      throw new Error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LABORATORY_PARTNER_SERVICE_GET_SIMILAR_SERVICE](
    _,
    partnerServiceGuid,
  ) {
    NProgress.start();

    try {
      const data = await api.get(
        `v3/laboratory/partner-service/similar-services?id=${partnerServiceGuid}`,
      );

      NProgress.done();

      return data;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LABORATORY_BRANCH_CREATE](_, params) {
    NProgress.start();
    try {
      await api.post('v3/laboratory/branch/create', params);
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Отделение создано!',
      });
      NProgress.done();
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось создать отделение',
      });

      throw new Error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.GET_LABORATORY_BRANCH_FOR_ID](_, branchId) {
    NProgress.start();

    try {
      const { data } = await api.get(`v3/laboratory/branch/get?id=${branchId}`);

      NProgress.done();

      return data;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LABORATORY_BRANCH_ACTIVATE](_, branchId) {
    NProgress.start();
    try {
      await api.post(`v3/laboratory/branch/activate?id=${branchId}`);
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Отделение активировано!',
      });
      NProgress.done();
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось активировать отделение',
      });

      throw new Error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LABORATORY_BRANCH_DEACTIVATE](_, branchId) {
    NProgress.start();
    try {
      await api.post(`v3/laboratory/branch/deactivate?id=${branchId}`);
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Отделение деактивировано!',
      });
      NProgress.done();
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось деактивировать отделение',
      });

      throw new Error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LABORATORY_BRANCH_UPDATE](_, params) {
    NProgress.start();
    let param = {};
    param = {
      code: params.code,
      name: params.name,
      cityId: params.cityId,
      address: params.address,
      legalPartnerId: params.legalPartnerId,
      longitude: params.longitude,
      latitude: params.latitude,
      isActive: params.isActive,
      operatingHoursTo: params.operatingHoursTo,
      operatingHoursFrom: params.operatingHoursFrom,
      subwayStations: params.subwayStations?.map((item) => item.name) || [],
      subwayColor: params.subwayColor,
    };
    try {
      await api.post(`v3/laboratory/branch/update?id=${params.id}`, param);
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Отделение изменено!',
      });
      NProgress.done();
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось изменить Отделение',
      });

      throw new Error(e);
    } finally {
      NProgress.done();
    }
  },

  async [localTypes.LABORATORY_SERVICE_CREATE](_, params) {
    NProgress.start();
    try {
      const data = await api.post('v3/laboratory/service/create', params);
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Услуга создана!',
      });
      NProgress.done();
      return data;
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось создать услугу',
      });

      throw new Error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LABORATORY_SERVICE_ACTIVATE](_, serviceId) {
    NProgress.start();
    try {
      await api.post(`v3/laboratory/service/activate?id=${serviceId}`);
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Услуга активирована!',
      });
      NProgress.done();
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось активировать услугу',
      });

      throw new Error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LABORATORY_SERVICE_DEACTIVATE](_, serviceId) {
    NProgress.start();
    try {
      await api.post(`v3/laboratory/service/deactivate?id=${serviceId}`);
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Услуга деактивирована!',
      });
      NProgress.done();
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось деактивировать услугу',
      });

      throw new Error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LABORATORY_SERVICE_UPDATE](_, params) {
    NProgress.start();
    let param = {};
    param = {
      code: params.code,
      name: params.name,
      childIds: params.childs,
      description: params.description,
      productionTime: params.productionTime,
      preparations: params.preparations,
      contraindications: params.contraindications,
      decoding: params.decoding,
      cost: params.cost,
      isActive: params.isActive,
      tags: params.tags,
      synonyms: params.synonyms,
      isRecommended: params.isRecommended,
      recommendationPriority: params.recommendationPriority,
    };
    try {
      await api.post(`v3/laboratory/service/update?id=${params.id}`, param);
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Услуга изменена!',
      });
      NProgress.done();
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось изменить услугу',
      });

      throw new Error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.CITY_UPDATE](_, params) {
    NProgress.start();
    try {
      await api.put('/Cities', params);
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Город успешно сохранен!',
      });
      NProgress.done();
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось сохранить город',
      });

      throw new Error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.CITY_DELETE](ctx, id) {
    NProgress.start();
    try {
      await api.delete(`/Cities/${id}`);
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Город успешно удалён!',
      });
    } catch (e) {
      console.warn(e);
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось удалить город',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LABORATORY_BRANCH_IMPORT](_, params) {
    NProgress.start();
    try {
      const fd = new FormData();

      if (params.importFile) {
        fd.append('file', params.importFile);
      }

      await api.post(
        `v3/laboratory/branch/import?legalPartnerId=${params.legalPartnerId}&importType=${params.importType}`,
        fd,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Отделения импортировались!',
      });
      NProgress.done();
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось импортировать отделения',
      });

      throw new Error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LABORATORY_PROMOCODE_FETCH](ctx, id) {
    NProgress.start();

    try {
      const { data } = await api.get(`v3/promo-code/laboratory/${id}`);
      return data;
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось получить информацию о промокоде',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LABORATORY_PROMOCODE_CREATE](ctx, params) {
    NProgress.start();

    try {
      await api.post('v3/promo-code/laboratory', params);

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Промокод успешно добавлен!',
      });
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LABORATORY_PROMOCODE_UPDATE](_, params) {
    NProgress.start();

    try {
      await api.put(`v3/promo-code/laboratory/${params.id}`, params);
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Промокод успешно изменен!',
      });
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LABORATORY_PROMOCODE_DELETE](ctx, id) {
    NProgress.start();

    try {
      await api.delete(`v3/promo-code/${id}`);
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Промокод успешно удален!',
      });
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LABORATORY_PROMOCODES_FETCH](
    { commit, state },
    {
      code, isActual, offset, limit, legalPartnerId,
    } = {},
  ) {
    NProgress.start();

    if (state.isLoading) return;

    // commit(localTypes.LABORATORY_PROMOCODES_IS_LOADING_SET, true);
    // commit(localTypes.LABORATORY_PROMOCODES_IS_ALL_ITEMS_LOADED_SET, false);

    try {
      const params = new URLSearchParams();

      if (code) params.append('code', code);
      if (offset) params.append('offset', offset);
      if (limit) params.append('limit', limit);
      if (isActual) params.append('onlyActual', isActual);
      if (legalPartnerId) params.append('legalPartnerId', legalPartnerId);

      const {
        data: { data: { paginatedCollection, originalCount } },
      } = await api.get('v3/promo-code/laboratory', {
        params,
      });
      commit(localTypes.LABORATORY_PROMOCODES_SET, paginatedCollection);
      commit(localTypes.LABORATORY_PROMOCODES_COUNT_SET, originalCount);
      // if (prevTotal + state.limit > state.promocodes.length) {
      commit(localTypes.LABORATORY_PROMOCODES_IS_LOADING_SET, true);
      // }
    } finally {
      NProgress.done();

      commit(localTypes.LABORATORY_PROMOCODES_IS_LOADING_SET, false);
    }
  },
  async [localTypes.LABORATORY_REMOVE_ATTACHED_FILE]({ commit }, {
    fileId,
    orderId,
  }) {
    NProgress.start();
    try {
      await api.delete('v3/laboratory/order/delete-result-file', {
        params: { fileId },
      });
      commit(localTypes.LABORATORY_REMOVE_ATTACHED_FILE, { fileId, orderId });
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Ошибка при скачивании файла',
      });

      throw new Error(e);
    } finally {
      NProgress.done();
    }
  },
};
