<script>
import { momSchoolService } from '@/services';
import Bus from '@/eventBus';
import { format, parseISO } from '@evd3v/date-fns';

export default {
  name: 'DeleteEventModal',
  props: {
    event: {
      type: Object,
      default: null,
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
    };
  },
  methods: {
    parseISO,
    format,
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async deleteEvent() {
      try {
        this.isLoading = true;
        await momSchoolService.deleteEvent({ id: this.event.id });
        Bus.$emit('deleted');
      } catch (e) {
        console.warn(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<template>
  <b-modal
    v-model="isOpen"
    centered
    title="Удаление вебинара"
    cancel-title="Отменить"
    ok-title="Удалить"
    cancel-variant="danger"
    :cancel-disabled="isLoading"
    :ok-disabled="isLoading"
    @hidden="onClose"
    @ok="deleteEvent"
  >
    <p>Вы уверены, что хотите удалить вебинар?</p>
    <dl>
      <dt>Название:</dt>
      <dd>{{ event.name }}</dd>
      <dt>Дата и время:</dt>
      <dd>{{ format(parseISO(event.startDate), "dd.MM.yyyy HH:mm") }}</dd>
    </dl>
  </b-modal>
</template>

<style scoped lang="scss">

</style>
