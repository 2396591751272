/* eslint-disable import/prefer-default-export */
import { z } from 'zod';

export const phoneSchema = z.object({
  phoneNumber: z
    .string()
    .min(1, 'Номер телефона обязателен')
    .regex(
      /^\+7\(\d{3}\) \d{3}-\d{2}-\d{2}$|^8\(\d{3}\) \d{3}-\d{2}-\d{2}$/,
      'Номер телефона должен быть в формате +7(XXX) XXX-XX-XX или 8(XXX) XXX-XX-XX',
    )
    .refine(
      (value) => value.startsWith('+7') || value.startsWith('8'),
      'Номер должен начинаться на +7 или 8',
    ),
});
