import VtbFormApi from '@/services/vtb-form/vtbForm.api';

export default class VtbFormService {
  constructor($axios) {
    this.api = new VtbFormApi($axios);
  }

  setFinalStatus(params) {
    return this.api.setFinalStatus(params);
  }

  setQuestionnaire(params) {
    return this.api.setQuestionnaire(params);
  }
}
