import MomSchoolApi from '@/services/mom-school/mom-school.api';

export default class MomSchoolService {
  constructor($axios) {
    this.api = new MomSchoolApi($axios);
  }

  getEvents(params) {
    return this.api.getEvents(params);
  }

  createEvent(params) {
    return this.api.createEvent(params);
  }

  editEvent(params) {
    return this.api.editEvent(params);
  }

  deleteEvent(params) {
    return this.api.deleteEvent(params);
  }
}
